import React from 'react'
import { useContext } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ChecklistContext } from '../../hooks/checklistContext';


const DraggableEducationBox = ({ data, index }) => {
    const { setData } = useContext(ChecklistContext)
    const [{ isDragging }, drag] = useDrag({
        type: 'Questions',
        item: { index },
    });

    const [, drop] = useDrop({
        accept: 'Questions',
        hover: (item) => {
            if (item.index !== index) {
                moveQuestion(item.index, index);
                item.index = index;
            }
        },
    });
    const grabStyle = {
        cursor: 'grab',
        // Add any other grabbing styles you want
    };

    const grabbingStyle = {
        cursor: 'grab',
        // Add any other grabbing styles you want
    };
    const moveQuestion = (fromIndex, toIndex) => {
        setData(prevState => {
            let newState = { ...prevState };
            const newQuestions = [...newState.questions]
            newQuestions.splice(fromIndex, 0)
            const [movedItem] = newQuestions.splice(fromIndex, 1);
            newQuestions.splice(toIndex, 0, movedItem);
            newState.questions = newQuestions;
            return newState
        })
    };
    const boxStyle = isDragging ? grabbingStyle : grabStyle;
    return (
        <tr ref={(node) => drag(drop(node))} style={{ marginBottom: '10px', ...boxStyle }}>
            {
                data.fields?.map((field, fdx) => {
                    return <td key={fdx}>{field}</td>
                }
                )
            }
        </tr>
    );
};

export default DraggableEducationBox;