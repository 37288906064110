import swal from "sweetalert";
import './index.css'
const Alert = (icon, message) => {
  swal({
    icon: icon,
    text: message,
    button: false,
    timer: 2000,
    customClass: {
      popup: 'my-popup-class', // Define your custom class here
    },
  });
};

const DeleteAlert = () => {
  return new Promise((resolve) => {
    swal({
      title: "Are you sure?",
      text: "You want to delete this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      closeOnClickOutside: false,
    }).then((deleted) => {
      if (deleted) {
        resolve(true);
      }
      resolve(false);
    });
  });
};

const UpdateAlert = () => {
  return new Promise((resolve) => {
    swal({
      title: "Are you sure?",
      text: "You want to update this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      closeOnClickOutside: false,
    }).then((deleted) => {
      if (deleted) {
        resolve(true);
      }
      resolve(false);
    });
  });
};

export { DeleteAlert, UpdateAlert };

export default Alert;
