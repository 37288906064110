import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'

import useCategory from '../../hooks/useCategory'

import Loader from '../../components/Loader'
import Screen from '../../components/Screen'
import Table from '../../components/Table'
import NewCategory from './NewCategory'

const Categories = () => {
  const {
    deleteLoading,
    addLoading,
    loading,
    categories,
    setData,
    fetchCategories,
    removeCategory,
    changeCategory,
    newCategory,
    data: fieldData
  } = useCategory();
  const ref = useRef();

  const labels = [
    '#',
    'Name',
    'Checklists',
    'Actions'
  ];
  const data = categories
    ?.map((item) => {
      return {
        data: item,
        fields: [
          item?._id?.substr(item?._id.length - 5),
          item.name,
          item.checklists,
          <div className='d-flex gap-3'>
            <i onClick={() => {
              ref.current?.scrollIntoView();
              setData(item)
            }}
              className="bi bi-pencil-square text-primary" style={{ cursor: 'pointer' }}></i>
            <Loader loading={deleteLoading?._id == item._id} color='red'>
              <i onClick={() => removeCategory(item)} className="bi bi-trash text-danger" style={{ cursor: 'pointer' }}></i>
            </Loader>
          </div>
        ]
      }
    })

  useEffect(() => {
    fetchCategories({ hide: ['Default'] });
  }, []);

  return (
    <Screen loading={loading} flow={[
      { label: 'Checklist Categories', to: '/categories' },
      { label: 'Home', to: '/' },
      { label: 'Checklist Categories', to: '/categories' }
    ]}>
      <div ref={ref} className='row'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4'>
          <NewCategory
            data={fieldData}
            setData={setData}
            onSubmit={fieldData._id ? changeCategory : newCategory}
            loading={addLoading}
          />
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8'>
          <Table
            title={'Checklist Categories'}
            data={data}
            labels={labels}
          />
        </div>
      </div>
    </Screen>
  )
}

export default Categories