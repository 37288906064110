import React, { useEffect, useState, useRef, memo } from "react";
import SignatureCanvas from "react-signature-canvas";
import { b64toBlob, capitalFirst } from "../config/functions";

import styles from "./styles.module.css";
import { Box, Card, Checkbox, MenuItem, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const FormField = ({ item, disabled, onClick, update, style }) => {
  const [show, setShow] = useState(false);
  const canvasRef = useRef();
  const [showDropdown, setShowDropdown] = useState(false);
  const onChange = (e) => {
    //(e.target.value)
    item.setValue(e.target.value);
  };
  const onCheckBoxChange = (e) => {
    //(e.target.value)
    item.setValue(e.target.value, true);
  };
  const riskOptions = ["--Select an option--", "high", "medium", "low"];
  const yesNo = ["--Select an option--", "yes", "no", "n/a"];
  const safeAtRisk = ["--Select an option--", "Safe", "At Risk"];
  const severity = ["--Select an option--", "Negligible", "Minor", "Moderate", "Significant", "Severe"];
  const likelihood = ["--Select an option--", "Very Unlikely", "Unlikely", "Possible", "Likely", "Very Likely"];


  useEffect(() => {
    switch (item?.type) {
      case "yes/no/na": {
        item.setValue(yesNo[0]);
        break;
      }
      case "high/medium/low": {
        item.setValue(riskOptions[0]);
        break;
      }
      case "high/medium/low": {
        item.setValue(safeAtRisk[0]);
        break;
      }
      case "severity": {
        item.setValue(severity[0]);
        break;
      }
      case "likelihood": {
        item.setValue(likelihood[0]);
        break;
      }
    }
  }, []);

  return (
    <div
      className={item.className ? item.className : item.type != 'multiSelect' ? `w-100` : ''}
      onClick={onClick}
      style={{ position: "relative" }}
    >
      {item?.type == "radio" ? (
        <fieldset className="row mb-3">
          {item?.label ? (
            <label htmlFor={item?.id} className="form-label">
              {item?.label}
            </label>
          ) : null}
          <div className="col-sm-12">
            {item?.options.map((option, index) => (
              <div className="form-check" key={index}>
                <input
                  disabled={disabled}
                  className="form-check-input"
                  type="radio"
                  name="gridRadios"
                  id="gridRadios1"
                  value={option}
                  checked={option == item?.value}
                  onChange={onChange}
                />
                <label className="form-check-label" htmlFor="gridRadios1">
                  {option}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
      ) : item?.type == "Check Box" ? (
        <fieldset className="row mb-3">
          {item?.label ? (
            <label htmlFor={item?.id} className="form-label">
              {item?.label}
            </label>
          ) : null}
          <div className="col-sm-12">
            {item?.options.map((option, index) => (
              <div className="form-check" key={index}>
                <input
                  disabled={disabled}
                  className="form-check-input"
                  type="checkbox"
                  name="gridRadios"
                  id="gridRadios1"
                  value={option}
                  checked={item?.value[0]?.includes(option)}
                  onChange={onCheckBoxChange}
                />
                <label className="form-check-label" htmlFor="gridRadios1">
                  {option}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
      ) : item?.type == "dropdown" ? (
        <div className="row mb-3">
          {item?.label ? (
            <label htmlFor={item?.id} className="form-label">
              {item?.label}
            </label>
          ) : null}
          <div className="col-sm-12">
            <select
              disabled={disabled}
              className="form-select"
              aria-label="Default select example"
              onChange={onChange}
            >
              <option selected={!item?.value} value={item.default}>
                {item.placeholder}
              </option>
              {item?.options?.map((val, index) => {
                const value = !!val.name ? val._id : val;
                const display = !!val.name ? val.name : val;
                return (
                  <option
                    key={index}
                    selected={item?.value == value}
                    value={value}
                  >
                    {capitalFirst(display)}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      ) : item?.type == "yes/no/na" ? (
        <div className="form-check">
          {item?.label ? (
            <label htmlFor={item?.id} className="form-label">
              {item?.label}
            </label>
          ) : null}
          <div className="col-sm-12">
            <select
              disabled={disabled}
              className="form-select"
              aria-label="Default select example"
              onChange={onChange}
            >
              {yesNo?.map((value, index) => (
                <option
                  key={index}
                  selected={item?.value == value}
                  value={value}
                >
                  {capitalFirst(value)}
                </option>
              ))}
            </select>
          </div>
        </div>
      ) : item?.type == "multiSelect" ? (
        <Box sx={{ ...item.style }}>
          {item.selected?.length > 0 && !item.isScreen && <label>{item.label}</label>}
          <div
            style={{
              width: "100%",
              borderRadius: showDropdown ? "10px 10px 0px 0px" : "10px",
              border: "1px solid rgba(0,0,0,0.2)",
              backgroundColor: "white",
              minHeight: "40px",
              height: item.isScreen && "40px",
              overflow: "hidden",
              cursor: "pointer",
              padding: "6px 10px",
              display: "flex",
              flex: 1,
              flexWrap: "wrap",
              gap: "10px",
            }}
            onClick={() => setShowDropdown(!showDropdown)}
          >
            {item.selected.length > 0 ? (
              item.selected?.map((item1, index) => (
                <Box
                  sx={{
                    borderRadius: "6px",
                    backgroundColor: "#00000012",
                    // marginRight: "4px",
                    padding: "4px 8px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",

                    // marginBottom:'5px'
                  }}
                >
                  <Typography key={index}>{item1.name}</Typography>
                  <CloseIcon
                    onClick={() => {
                      item?.setSelected((prevState) => {
                        const newState = [...prevState];

                        let updatedState = newState.filter(
                          (item2) => item2._id != item1._id
                        );
                        return updatedState;
                      });
                    }}
                    sx={{
                      height: "20px",
                      marginLeft: "10px",
                    }}
                  />
                </Box>
              ))
            ) : (
              <Typography>{item.label}</Typography>
            )}
          </div>
          {showDropdown && (
            <Card
              sx={{
                position: "absolute",
                top: "100%",
                left: item.isScreen ? "0px" : "10px",
                backgroundColor: "white",
                width: item.isScreen ? "100%" : "97%",
                maxHeight: "180px",
                overflow: "auto",
                border: "1px solid rgba(0,0,0,0.2)",
                borderRadius: "0px 0px 10px 10px",
                zIndex: "99",
              }}
            >
              <MenuItem>
                <Checkbox
                  checked={item.selected?.length === item.menuItems?.length}
                  onChange={(e) => {
                    if (e.target.checked) {
                      item?.setSelected(item.menuItems)
                    } else {
                      item?.setSelected([])
                    }
                  }}
                />{" "}
                {'Select All'}
              </MenuItem>
              {item.menuItems &&
                item.menuItems?.map((item1, index) => (
                  <MenuItem key={index}>
                    <Checkbox
                      checked={item.selected.some(
                        (item2) => item2._id == item1._id
                      )}
                      onChange={() => {
                        item?.setSelected((prevState) => {
                          const newState = [...prevState];
                          if (
                            newState.some((item2) => item2._id == item1._id)
                          ) {
                            let updatedState = newState.filter(
                              (item2) => item2._id != item1._id
                            );
                            return updatedState;
                          } else {
                            newState.push(item1);
                            return newState;
                          }
                        });
                        if (item.isScreen) {
                          setShowDropdown(false)
                        }
                      }}
                    />{" "}
                    {item1.name}
                  </MenuItem>
                ))}
            </Card>
          )}
        </Box>
      ) : item?.type == "Safe/At Risk" ? (
        <div className="form-check">
          {item?.label ? (
            <label htmlFor={item?.id} className="form-label">
              {item?.label}
            </label>
          ) : null}
          <div className="col-sm-12">
            <select
              disabled={disabled}
              className="form-select"
              aria-label="Default select example"
              onChange={onChange}
            >
              {safeAtRisk?.map((value, index) => (
                <option
                  key={index}
                  selected={item?.value == value}
                  value={value}
                >
                  {capitalFirst(value)}
                </option>
              ))}
            </select>
          </div>
        </div>
      ) : item?.type == "boolean" ? (
        <div className="form-check form-switch">
          <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
            {item.label}
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            id="flexSwitchCheckDefault"
            checked={Array.isArray(item.value) ? item.value[0] : item.value}
            onChange={(e) =>
              item.setValue(
                Array.isArray(item.value) ? !item.value[0] : !item.value
              )
            }
            disabled={item.disabled}
          />
        </div>
      ) : item?.type == "high/medium/low" ? (
        <div className="row mb-3">
          {item?.label ? (
            <label htmlFor={item?.id} className="form-label">
              {item?.label}
            </label>
          ) : null}
          <div className="col-sm-12">
            <select
              disabled={disabled}
              className="form-select"
              aria-label="Default select example"
              onChange={onChange}
            >
              {riskOptions?.map((value, index) => (
                <option
                  key={index}
                  selected={item?.value == value}
                  value={value}
                >
                  {capitalFirst(value)}
                </option>
              ))}
            </select>
          </div>
        </div>
      ) : item?.type == "severity" ? (
        <div className="row mb-3">
          {item?.label ? (
            <label htmlFor={item?.id} className="form-label">
              {item?.label}
            </label>
          ) : null}
          <div className="col-sm-12">
            <select
              disabled={disabled}
              className="form-select"
              aria-label="Default select example"
              onChange={onChange}
            >
              {severity?.map((value, index) => (
                <option
                  key={index}
                  selected={item?.value == value}
                  value={value}
                >
                  {capitalFirst(value)}
                </option>
              ))}
            </select>
          </div>
        </div>
      ) : item?.type == "likelihood" ? (
        <div className="row mb-3">
          {item?.label ? (
            <label htmlFor={item?.id} className="form-label">
              {item?.label}
            </label>
          ) : null}
          <div className="col-sm-12">
            <select
              disabled={disabled}
              className="form-select"
              aria-label="Default select example"
              onChange={onChange}
            >
              {likelihood?.map((value, index) => (
                <option
                  key={index}
                  selected={item?.value == value}
                  value={value}
                >
                  {capitalFirst(value)}
                </option>
              ))}
            </select>
          </div>
        </div>
      ) : item?.type == "options" ? (
        <div className="row mb-3">
          {item?.label ? (
            <label htmlFor={item?.id} className="form-label">
              {item?.label}
            </label>
          ) : null}
          <div className="col-sm-12">
            <div className="btn-group d-flex flex-column" role="group">
              {item.options?.map((value, index) => {
                const checked =
                  (Array.isArray(item.value) ? item.value[0] : item.value) ==
                  value;
                return (
                  <div
                    style={{ cursor: "pointer" }}
                    key={index}
                    className="d-flex flex-row gap-2"
                    onClick={() => item.setValue(value)}
                  >
                    <i
                      className={
                        checked
                          ? "text-primary bi bi-record-circle"
                          : "bi bi-circle"
                      }
                    ></i>
                    <input
                      type="radio"
                      className="btn-check"
                      checked={checked}
                    />
                    <span
                      className={checked ? "text-primary" : "text-secondary"}
                    >
                      {value}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : item?.type == "signature" ? (
        <div className="row mb-3">
          {item?.label ? (
            <label htmlFor={item?.id} className="form-label">
              {item?.label}
            </label>
          ) : null}
          <div div className="w-100 border border-secondary mb-2">
            <SignatureCanvas
              ref={canvasRef}
              penColor="green"
              canvasProps={{ className: styles.sigPad }}
              onEnd={() => {
                const canvas = canvasRef.current;
                const b64 = canvas?.toDataURL("image/png");
                const convertedB64 = b64toBlob(b64);
                item.setValue(convertedB64);
              }}
            />
          </div>
          <button
            onClick={() => {
              const canvas = canvasRef.current;
              canvas?.clear();
              item.setValue("");
            }}
            className="btn btn-outline-primary"
          >
            Clear
          </button>
        </div>
      ) : item?.type == "textarea" ? (
        <>
          {item?.hintLabel ? (
            <label htmlFor={item?.id} className="form-label mt-3">
              {item?.hintLabel}
            </label>
          ) : null}
          <div className={`row mb-3 ${item.hintLabel && "mx-1"} `}>
            {item?.label ? (
              <label htmlFor={item?.id} className="form-label">
                {item?.label}
              </label>
            ) : null}
            <textarea
              className="form-control"
              id={item?.id}
              value={[Array.isArray(item.value) ? item.value[0] : item.value]}
              onChange={onChange}
              placeholder={item.placeholder}
              disabled={disabled}
              min={item.min}
              max={item.max}
            />
          </div>
        </>
      ) : (item?.type == "statement" || item?.type == "statement/Instruction") ? (
        <></>
      ) : (
        <div style={{ ...item.style }}>
          {item?.label ? (
            <label htmlFor={item?.id} className="form-label">
              {item?.label}
            </label>
          ) : null}
          {disabled && (item.type == "file" || item.type == "signature") ? (
            <button
              className="btn btn-outline-primary w-100"
              onClick={() => {
                //(item)
                var win = window.open(
                  Array.isArray(item.value) ? item.value[0] : item.value,
                  "_blank"
                );
                win.focus();
              }}
            >
              View
            </button>
          ) : (
            <div className="input-group w-100">
              <input
                className="form-control"
                id={item?.id}
                onFocus={update}
                type={
                  item.type == "free text"
                    ? "text"
                    : item.type == "signature"
                      ? "file"
                      : item.type == "password"
                        ? show
                          ? "text"
                          : "password"
                        : item.type
                }
                value={
                  item.type == "file" || item.type == "signature"
                    ? undefined
                    : Array.isArray(item.value)
                      ? item.value[0]
                      : item.value
                }
                onChange={
                  item.type != "file" && item.type != "signature"
                    ? onChange
                    : (event) => {
                      item.setValue(event.target.files[0]);
                    }
                }
                placeholder={item.placeholder}
                disabled={disabled}
                min={item.min}
                max={item.max}
              />
              {item.type != "password" ? null : (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setShow(!show);
                  }}
                  className="btn btn-outline-secondary"
                >
                  <i className={`bi bi-eye${show ? "-slash" : ""}`}></i>
                </button>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default memo(FormField);
