import React, { memo, useEffect, useState } from "react";
import Table from "../../components/Table";
import api from "../../app/api";
import Screen from "../../components/Screen";
import EmptyData from "../../components/EmptyData";
import useAuth from "../../hooks/useAuth";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDF from "./pdf";
const FilesList = () => {
  //   const { policies } = useSelector((state) => state.Policy);
  const [submissionData, setSubmissionData] = useState([]);
  const { isCompany, isAdmin, user, isManager } = useAuth();
  const [limit, setlimit] = useState(10);
  const [page, setPage] = useState(1);
  const [filter, setfilter] = useState({ company: "", branch: "" });
  const [loading, setloading] = useState(false);
  const getData = async () => {
    setloading(true);
    try {
      const response = await api.get(
        `/shareSubmissions/get?${
          isCompany
            ? `id=${localStorage.getItem("_id")}`
            : isManager
            ? `id=${user.branchID}`
            : `companyID=${filter.company}`
        }&page=${page}&limit=${limit}&branch=${filter.branch}`,
        {
          ...(isCompany && { id: localStorage.getItem("_id") }),
          ...(isAdmin && { ...filter }),
        }
      );
      setSubmissionData(response.data.result);
    } catch (error) {
    } finally {
      setloading(false);
    }
  };
  const labels = [
    "#",
    "Submitted By",
    "Type",
    "Submitted At",
    "Signature",
    "Content",
  ].filter((item) => item);
  const data = submissionData
    ? submissionData.map((item) => {
        return {
          data: item,
          fields: [
            item._id?.substring(0, 5),
            item?.submittedEmail,
            item?.forFeature,
            item?.createdAt?.substring(0, 10),
            <PDFDownloadLink
              document={<PDF data={item} />}
              fileName={`email-confirmation-`+item?.submittedEmail + `.pdf`}
            >
              {() => {
                return (
                  <button
                    onClick={() => {
                      // if (template) {
                      //   navigator("/policyInput", { state: item });
                      // } else {
                      //   onView(item);
                      // }
                    }}
                    className="btn btn-sm btn-success"
                  >
                    Open
                  </button>
                );
              }}
            </PDFDownloadLink>,
            <a
              href={`/shareView?id=${
                item.shareableID?._id
              }&isUser=${true}&userID=${localStorage.getItem("_id")}`}
              target="_blank"
            >
              View
            </a>,
          ].filter((it) => it),
        };
      })
    : [];
  useEffect(() => {
    getData();
  }, [filter, page]);

  return (
    <Screen
      loading={loading}
      filter={filter}
      setFilter={setfilter}
      showBranchFilter={isCompany || isAdmin}
      flow={[
        { label: "Email Confirmations", to: "/emailConfirmations" },
        { label: "Home", to: "/" },
        { label: "Email Confirmations", to: "/emailConfirmations" },
      ]}
    >
      <EmptyData data={data}>
        <Table
          labels={labels}
          data={data}
          limit={limit}
          page={page}
          setPage={setPage}
        />
      </EmptyData>
    </Screen>
  );
};

export default memo(FilesList);
