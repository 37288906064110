// Public Pages
import Login from "../features/auth/Login";
import Signup from "../features/auth/Signup";
import Forgot from "../features/auth/Forgot";
import Reset from "../features/auth/Reset";
import Confirmation from "../features/auth/Confirmation";

// Protected Pages
import Dashboard from "../features/dashboard/Dashboard";
import Training from "../features/training/Training";

// Checklist
import Collection from "../features/checklist/Collection";
import NewChecklist from "../features/checklist/NewChecklist";

// Risk Assesment
import RiskAssesmentCollection from "../features/riskAssesment/Collection"
import NewRiskAssesment from "../features/riskAssesment/NewRiskAssesment"

// Tasks
import Tasks from "../features/tasks/Tasks";
import Task from "../features/tasks/Task";
import PDF from "../features/tasks/PDF";

// Admin
import Documents from "../features/documents/Documents";
import ManagementSystem from "../features/managementSystem";
import Records from "../features/records/Records";
import Companies from "../features/companies/Companies";
import Categories from "../features/category/Categories";
import Natures from "../features/nature/Natures";
import Admins from "../features/admin/Admins";
import Profile from "../features/profile/Profile";
import Branches from "../features/companies/Branches";
import Subscription from "../features/subscription";

// Communication
import Broadcasts from "../features/broadcast/Broadcasts";
import BroadcastDetails from "../features/broadcast/BroadcastDetails";
import Chat from "../features/chat/Chat";
import Email from "../features/emails";
import Share from "../features/share";
import ShareView from "../features/shareView"
import ShareSubmission from "../features/shareSubmissions"

// Policies
import Policies from "../features/policies";
import PoliciesInput from "../features/policies/policiesInput";
import PoliciesOptions from "../features/policies/chooseOptiontoAdd";

export const protectedRoutes = [
  { path: "/dashboard", element: <Dashboard /> },
  { path: "/training", element: <Training /> },
  { path: "/checklists", element: <Collection /> },
  { path: "/new-checklist", element: <NewChecklist /> },
  { path: "/tasks", element: <Tasks /> },
  { path: "/risk-assesments", element: <RiskAssesmentCollection /> },
  { path: "/new-risk-assesment", element: <NewRiskAssesment /> },

  { path: "/task", element: <Task /> },
  { path: "/pdf", element: <PDF /> },
  { path: "/documents", element: <Documents /> },
  { path: "/records", element: <Records /> },
  { path: "/companies", element: <Companies /> },
  { path: "/branches", element: <Branches /> },
  { path: "/categories", element: <Categories /> },
  { path: "/natures", element: <Natures /> },
  { path: "/admins", element: <Admins /> },
  { path: "/profile", element: <Profile /> },

  { path: "/broadcast", element: <Broadcasts /> },
  { path: "/broadcast-details", element: <BroadcastDetails /> },
  { path: "/inbox", element: <Chat /> },
  { path: "/policy", element: <Policies /> },
  { path: "/policyInput", element: <PoliciesInput /> },
  { path: "/policies-options", element: <PoliciesOptions /> },
  { path: "/email", element: <Email /> },
  { path: "/share", element: <Share /> },
  { path: "/emailConfirmations", element: <ShareSubmission /> },
  { path: "/subscription", element: <Subscription /> },
  { path: "/management-system", element: <ManagementSystem /> },
];

export const publicRoutes = [
  { path: "/login", element: <Login /> },
  { path: "/signup", element: <Signup /> },
  { path: "/forgot", element: <Forgot /> },
  { path: "/Reset", element: <Reset /> },
  { path: "/confirmation", element: <Confirmation /> },
  { path: "/shareView", element: <ShareView /> },
];
