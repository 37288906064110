import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Screen from "../../components/Screen";
import SubmitButton from "../../components/SubmitButton";
import { PoliciesContext } from "../../hooks/policiesContext";
import useAuth from "../../hooks/useAuth";
import useManagementSystem from "../../hooks/useManagementSystem";
import AddDocumentModal from "../documents/AddDocumentModal";
import FilesList from "./FilesList";
import CompanyModal from "../../components/companyModel";
export default function Default() {
  const { isAdmin } = useAuth();
  const {
    page,
    limit,
    docData,
    modalName,
    showAddModal,
    setDocData,
    setPage,
    setModalName,
    setShowAddModal,
    changeDocument,
    fetchDocuments,
    loading,
    AddNewDocument,
    selectedCategories,
    setSelectedCategories,
    selectedNature,
    setselectedNature,
    catFilter,
    setCatFilter,
    natureFilter,
    setNatureFilter,
    addLoading,
  } = useManagementSystem();
  const [accurate, setAccurate] = useState(false);
  const { selectedForShare } = useContext(PoliciesContext);
  const [showCompanyModel, setShowCompanyModel] = useState(false);
  const handleshowAddModal = () => {
    setDocData({
      ...docData,
    });
    setShowAddModal(true);
    setModalName("Add Document");
  };
  const [query] = useSearchParams();
  const isPublic = query?.get("public");
  const noActions = isPublic && !isAdmin;

  useEffect(() => {
    fetchDocuments(natureFilter, catFilter, accurate);
  }, [natureFilter, catFilter, page, accurate]);

  return (
    <>
      <Screen
        loading={loading}
        flow={[
          { label: "Management System", to: "/management-system" },
          { label: "Home", to: "/" },
          { label: "Management System", to: "/management-system" },
        ]}
        selectedCategories={catFilter}
        setSelectedCategories={setCatFilter}
        selectedNature={natureFilter}
        setselectedNature={setNatureFilter}
        accurate={accurate}
        setAccurate={setAccurate}
      >
        <div
          style={{ flexDirection: "row-reverse", justifyContent: "right" }}
          className="col-12 d-flex mb-2 mt-2"
        >
          {isAdmin && (
            <SubmitButton
              title={"Add document"}
              onClick={handleshowAddModal}
              color={"success"}
              loaderColor={"white"}
            />
          )}
          {isAdmin && (
            <SubmitButton
              title={"Assign"}
              onClick={() => setShowCompanyModel(true)}
              color={"success"}
              loaderColor={"white"}
              disabled={selectedForShare.length < 1}
              style={{ marginRight: "15px" }}
            />
          )}
        </div>
        <div className="col-12">
          <FilesList
            noActions={noActions}
            setModalName={setModalName}
            setShowAddModal={setShowAddModal}
            setDocData={setDocData}
            docData={docData}
            page={page}
            limit={limit}
            setPage={setPage}
            setSelectedCategories={setSelectedCategories}
            setSelectedNature={setselectedNature}
          />
        </div>
        <AddDocumentModal
          showAddModal={showAddModal}
          docData={docData}
          modalName={modalName}
          setDocData={setDocData}
          handleClose={() => setShowAddModal(false)}
          submit={modalName == "Add Document" ? AddNewDocument : changeDocument}
          addLoading={addLoading}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          selectedNature={selectedNature}
          setSelectedNature={setselectedNature}
          allowed={".docx, .pdf"}
        />
        <CompanyModal
          show={showCompanyModel}
          handleClose={setShowCompanyModel}
        />
      </Screen>
    </>
  );
}
