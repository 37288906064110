export const keys = {
  // BASE_URL: 'https://server.compcheck.io/api',
  BASE_URL: process.env.REACT_APP_BASE_URL,
  BASE_LOCAL_URL: process.env.REACT_APP_BASE_URL,
  API_KEY: 'AIzaSyCw80XxPqBfWlgOfe6DdPprJ_de0gritzY',
  AUTH_DOMAIN: 'safety-companion.firebaseapp.com',
  PROJECT_ID: 'safety-companion',
  STORAGE_BUCKET: 'safety-companion.appspot.com',
  MESSAGING_SENDER_ID: '972110524739',
  APP_ID: '1:972110524739:web:82d82c7c8f7139c9c4fff6',
  vapidKey: 'BOecBCjjrIWTwtpiCah_-9q0tchlkVwSfNvu9Heazqal_os3Xj0yA8gHiudEAhewLgTR7srqdum1sWbFjVtY58o',
  serverKey: '"AAAA4lZNcUM:APA91bGthpBS73Ncus266HPHXXNZ7lZyzx2ainkHVTBZkWWuUHNahSjQZtmeoQYjMvmHe1WYuCTGruFWjytu5PqnirHBofHN_CHYcmzg1-1cqSI984II1gNGJ7xgv-TSqoW8_f9eY_fo"',
};
