import React, { useEffect } from "react";
import useCategory from "../hooks/useCategory";
import useNature from "../hooks/useNature";
import FormField from "./FormField";
import { Box, Checkbox, Typography } from "@mui/material";
const CompanyFilter = ({
  selectedCategories,
  setSelectedCategories,
  selectedNature,
  setselectedNature,
  accurate,
  setAccurate,
}) => {
  const { natures, fetchNatures } = useNature();
  const { fetchCategories, categories } = useCategory();
  useEffect(() => {
    if (selectedCategories) fetchCategories({ hide: ["Default"] });
    if (selectedNature) fetchNatures({ hide: ["Default"] });
  }, []);

  return (
    <div className="d-flex gap-3" style={{ maxWidth: "100%" }}>
      <FormField
        item={{
          selected: selectedCategories,
          menuItems: categories,
          setSelected: setSelectedCategories,
          type: "multiSelect",
          label: "Select Categories",
          style: { width: "280px" },
          isScreen: true,
        }}
      />
      <FormField
        item={{
          selected: selectedNature,
          menuItems: natures,
          setSelected: setselectedNature,
          type: "multiSelect",
          label: "Select Natures",
          style: { width: "280px" },
          isScreen: true,
        }}
      />
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Checkbox checked={accurate} onChange={(e)=> setAccurate(e.target.checked)} />
        <Typography>Accurate</Typography>
      </Box>
    </div>
  );
};

export default CompanyFilter;
