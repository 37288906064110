import React, { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Screen from "../../components/Screen";
import { PoliciesContext } from "../../hooks/policiesContext";
import usePolicy from "../../hooks/usePolicy";
import FilesList from "./fileList";
import useAuth from "../../hooks/useAuth";

export default function Default() {
  const [query] = useSearchParams();
  const template = query.get("template");
  const { isAdmin, isCompany, user, isManager } = useAuth();
  const {
    getPolicies,
    filter,
    setFilter,
    loading,
    getTemplates,
    page,
    setPage,
    limit,
  } = usePolicy();
  const { removeAllFromShare } = useContext(PoliciesContext);
  useEffect(() => {
    removeAllFromShare();
    if (template) {
      getTemplates();
    } else {
      getPolicies(page);
    }
    return () => {};
  }, [filter, template]);
  useEffect(() => {
    if (template) {
      getTemplates();
    } else {
      getPolicies(page);
    }
  }, [page]);

  return (
    <Screen
      loading={loading}
      filter={filter}
      setFilter={setFilter}
      showShare={isAdmin || isCompany || isManager}
      flow={[
        { label: "Policies", to: "/policy" },
        { label: "Home", to: "/" },
        { label: "Policies", to: "/policy" },
      ]}
      allowed={
        template &&
        user?.subscriptionID?.allowedPOTemplates != undefined &&
        !user?.subscriptionID?.allowedPOTemplates &&
        !isAdmin
      }
    >
      <div className="col-12">
        <FilesList
          template={template}
          limit={limit}
          page={page}
          setPage={setPage}
        />
      </div>
    </Screen>
  );
}
