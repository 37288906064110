import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import useTask from "../../hooks/useTask";
import useAuth from "../../hooks/useAuth";

import Screen from "../../components/Screen";
import FormField from "../../components/FormField";
import SubmitButton from "../../components/SubmitButton";
import DownloadPopup from "../../components/DownloadPopup";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@mui/material";
const Task = () => {
  const location = useLocation();
  const {
    data,
    loading,
    questions,
    deleteLoading,
    fetchTaskById,
    performTask,
    getAnswer,
    setAnswer,
    onDelete,
  } = useTask();
  const { isEmployee, isManager } = useAuth();
  const [task, setTask] = useState();
  const frequency = location?.search.split("?frequency=")[1];
  const disabled = false;
  const fresh = location.state?.fresh;
  const isRiskAssesment = location.state?.isRiskAssesment;

  useEffect(() => {
    fetchTaskById(location.state?._id, { fresh, isRiskAssesment });
  }, []);

  return (
    <Screen
      flow={
        location.state?.notification || fresh
          ? [{ label: !data ? "" : `${data?.title}` }]
          : [
              { label: `${location?.state?.title}` },
              { label: "Home", to: "/" },
              {
                label: `${frequency}`,
                to: `/tasks${location?.search}`,
                state: location?.state,
              },
              {
                label: "Task",
              },
            ]
      }
    >
      <div className="d-flex flex-column align-items-end" id="parentTask">
        {(isEmployee && !isManager) || fresh ? null : (
          <div className="row d-flex justify-content-end mb-2">
            <SubmitButton
              icon={<i className="bi bi-trash3"></i>}
              loading={deleteLoading}
              onClick={() => onDelete(location.state?._id)}
              color={"danger"}
              loaderColor={"white"}
            />
          </div>
        )}
        <div className="row d-flex justify-content-evenly w-100" id="capture">
          {questions?.map((item, index) => {
            const isBoolean = item.type == "boolean";
            return (
              <div
                className={`card col-12 ${
                  item.type == "signature" || item.type == 'statement'
                    ? "col-lg-12 col-xl-12"
                    : "col-lg-5 col-xl-5 "
                }`}
              >
                <div
                  className={
                    "card-body" +
                    (isBoolean
                      ? " d-flex flex-column justify-content-center align-items-center h-100"
                      : "")
                  }
                >
                  <div
                    key={index}
                    className={
                      isBoolean
                        ? "d-flex justify-content-between align-items-center w-100"
                        : ""
                    }
                  >
                    <h5
                    style={{textAlign:'justify'}}
                      className={`${item.type != 'statement' ? 'card-title' :'mt-4'} d-flex`}
                      htmlFor={isBoolean ? "flexSwitchCheckDefault" : ""}
                    >
                      {item.question}
                      {item.required ? (
                        <span className="text-danger"> *</span>
                      ) : null}
                      {item.hintText ? (
                        <Tooltip title={item.hintText} enterTouchDelay={0}>
                          <InfoIcon
                            sx={{
                              fontSize: "18px",
                              marginLeft: "10px",
                              cursor: "pointer",
                            }}
                          />
                        </Tooltip>
                      ) : null}
                    </h5>
                    <div className="d-flex align-items-center">
                      <FormField
                        item={{
                          value: [getAnswer(item._id)],
                          setValue: (value, checkbox) => {
                            setAnswer(item._id, value, checkbox);
                          },
                          type: item.type,
                          options: item.options,
                        }}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {disabled ? (
          <button
            onClick={() => setTask(location?.state)}
            className="btn btn-primary"
          >
            {loading ? "Loading document..." : "Download now!"}
          </button>
        ) : (
          <SubmitButton
            title={
              data?.status == "Approved" ? "Update Answers" : "Submit Answers"
            }
            color={"primary"}
            loading={loading}
            onClick={() => performTask(fresh , isRiskAssesment)}
            loaderColor={"white"}
          />
        )}
      </div>
      <DownloadPopup task={task} handleClose={() => setTask(undefined)} isRiskAssesment={isRiskAssesment} />
    </Screen>
  );
};

export default Task;
