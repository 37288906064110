import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import UniversalFormFIeld from "../../components/FormField";
import SubmitButton from "../../components/SubmitButton";
import useCategory from "../../hooks/useCategory";
import useNature from "../../hooks/useNature";
import FormField from "../companies/FormField";

const AddDocumentModal = ({
  showAddModal,
  docData,
  setDocData,
  handleClose,
  modalName,
  submit,
  addLoading,
  selectedCategories,
  setSelectedCategories,
  selectedNature,
  setSelectedNature,
  allowed
}) => {
  const {
    natures,
    fetchNatures,
  } = useNature();
  const { fetchCategories, categories } = useCategory();
  useEffect(() => {
    if (selectedCategories) fetchCategories({ hide: ["Default"] });
    if(selectedNature) fetchNatures({ hide: ['Default'] });
  }, []);

  return (
    <>
      <Modal show={showAddModal} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="row g-3">
            {/* Input */}
            <FormField
              item={{
                value: docData?.name,
                setValue: (value) => {
                  setDocData({ ...docData, name: value });
                },
                required: true,
                type: "text",
                className: "col-9",
              }}
            />
            {/* File Button */}
            <div className="col-3">
              <label className="btn btn-secondary w-100" htmlFor="files">
                Browse
              </label>
              <input
                type="file"
                id="files"
                multiple
                max={5}
                className="hidden"
                accept={allowed} 
                onChange={(e) => {
                  const selectedFiles = Array.from(e.target.files);
                  setDocData({
                    ...docData,
                    file: e.target.files[0],
                    name: e.target.files[0].name,
                    files: selectedFiles,
                  });
                }}
              />
            </div>
            {selectedCategories && (
              <UniversalFormFIeld
                item={{
                  selected: selectedCategories,
                  menuItems: categories,
                  setSelected: setSelectedCategories,
                  type: "multiSelect",
                  label:"Select Categories"
                }}
              />
            )}
            {selectedNature && (
              <UniversalFormFIeld
                item={{
                  selected: selectedNature,
                  menuItems: natures,
                  setSelected: setSelectedNature,
                  type: "multiSelect",
                  label:"Select Natures"
                }}
              />
            )}
            
          </form>
        </Modal.Body>
        <Modal.Footer>
          <SubmitButton
            title={modalName}
            loading={addLoading}
            onClick={submit}
            color={"success"}
            loaderColor={"white"}
            disabled={!docData.file && !docData.categoryID && !docData.natureID}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddDocumentModal;
