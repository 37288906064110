import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'

import useNature from '../../hooks/useNature'

import Loader from '../../components/Loader'
import Screen from '../../components/Screen'
import Table from '../../components/Table'
import NewNature from './NewNature'

const Natures = () => {
  const {
    deleteLoading,
    addLoading,
    loading,
    natures,
    setData,
    fetchNatures,
    removeNature,
    changeNature,
    newNature,
    data: fieldData
  } = useNature();
  const ref = useRef();

  const labels = [
    '#',
    'Name',
    'Companies',
    'Actions'
  ];
  const data = natures
    ?.map((item) => {
      return {
        data: item,
        fields: [
          item?._id?.substr(item?._id.length - 5),
          item.name,
          item.companies,
          <div className='d-flex gap-3'>
            <i onClick={() => {
              ref.current?.scrollIntoView();
              setData(item)
            }}
              className="bi bi-pencil-square text-primary" style={{ cursor: 'pointer' }}></i>
            <Loader loading={deleteLoading?._id == item._id} color='red'>
              <i onClick={() => removeNature(item)} className="bi bi-trash text-danger" style={{ cursor: 'pointer' }}></i>
            </Loader>
          </div>
        ]
      }
    })

  useEffect(() => {
    fetchNatures({ hide: ['Default'] });
  }, []);

  return (
    <Screen loading={loading} flow={[
      { label: 'Nature of Businesses', to: '/natures' },
      { label: 'Home', to: '/' },
      { label: 'Nature of Businesses', to: '/natures' }
    ]}>
      <div ref={ref} className='row'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4'>
          <NewNature
            data={fieldData}
            setData={setData}
            onSubmit={fieldData._id ? changeNature : newNature}
            loading={addLoading}
          />
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8'>
          <Table
            title={'Nature of Businesses'}
            data={data}
            labels={labels}
          />
        </div>
      </div>
    </Screen>
  )
}

export default Natures