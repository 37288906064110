import React, { useEffect, useState } from "react";

import QuestionList from "./QuestionList";
import FormField from "../../components/FormField";
import { useContext } from "react";
import { RiskAssesmentContext } from "../../hooks/riskAssesmentContext";
import { Box } from "@mui/material";

const RiskAssesmentQuestions = ({
  data,
  setData,
  question,
  setQuestion,
  onAddQuestiuon,
  onDublicateQuestiuon
}) => {
  const { questionTypes, fetchQuestionTypes } =
    useContext(RiskAssesmentContext);
  const [sortOrder, setSortOrder] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  // Options
  const questionFields = [
    ...(question.type != "statement"
      ? [
          {
            hintLabel: "Guidance",
            value: question.hintText,
            setValue: (value) => setQuestion({ ...question, hintText: value }),
            type: "textarea",
            id: "riskAssesmentTitle",
            placeholder: "Add Guidance",
          },
        ]
      : []),
    {
      label: question.type == "statement" ? "Text" : "Question",
      value: question.question,
      setValue: (value) => setQuestion({ ...question, question: value }),
      type: "text",
      id: "riskAssesmentTitle",
    },
    {
      label: "Question Type",
      value: question.type,
      setValue: (value) => setQuestion({ ...question, type: value }),
      type: "dropdown",
      options: questionTypes,
      placeholder: "Select question type",
    },
    ...(question.type != "statement"
      ? [
          {
            label: "Required",
            value: question.required,
            setValue: (value) => setQuestion({ ...question, required: value }),
            type: "boolean",
          },
        ]
      : []),
  ];
  const optionFields = question.options?.map((item, index) => ({
    value: item,
    setValue: (value) => {
      const options = [...question.options];
      options[index] = value;
      setQuestion({ ...question, options });
    },
    type: "text",
    id: "riskAssesmentOptions",
  }));

  useEffect(() => {
    fetchQuestionTypes();
  }, []);

  return (
    <div className="row">
      {/* New Question */}
      {/* <div style={{ position: "relative" }} className="col-xl-4">
        <Box
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
          }}
        >
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Add Risk Assessment Question</h5>
              {questionFields?.map((item, index) => (
                <FormField key={index} item={item} />
              ))}
              {question.type != "options" ? null : (
                <div>
                  <h5 className="card-title">Options</h5>
                  <div className="gap-2 d-flex flex-column mb-2">
                    {optionFields?.map((item, index) => (
                      <div
                        className="d-flex gap-2 align-items-center"
                        key={index}
                      >
                        <i className="text-primary bi bi-record-circle"></i>
                        <FormField key={index} item={item} />
                        <i
                          onClick={() => {
                            const options = question.options?.filter(
                              (item, idx) => idx != index
                            );
                            setQuestion({ ...question, options });
                          }}
                          className="bi bi-trash text-danger"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                    ))}
                  </div>
                  <span
                    onClick={() => {
                      const options = question.options
                        ? [...question.options]
                        : [];
                      options.push("");
                      setQuestion({ ...question, options });
                    }}
                    className="text-primary"
                    style={{ cursor: "pointer" }}
                  >
                    + Add More
                  </span>
                </div>
              )}
              {question.type != "Check Box" ? null : (
                <div>
                  <h5 className="card-title">Options</h5>
                  <div className="gap-2 d-flex flex-column mb-2">
                    {optionFields?.map((item, index) => (
                      <div
                        className="d-flex gap-2 align-items-center"
                        key={index}
                      >
                        <i className="text-primary bi bi-record-circle"></i>
                        <FormField key={index} item={item} />
                        <i
                          onClick={() => {
                            const options = question.options.filter(
                              (item, idx) => idx != index
                            );
                            setQuestion({ ...question, options });
                          }}
                          className="bi bi-trash text-danger"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                    ))}
                  </div>
                  <span
                    onClick={() => {
                      const options = question.options
                        ? [...question.options]
                        : [];
                      options.push("");
                      setQuestion({ ...question, options });
                    }}
                    className="text-primary"
                    style={{ cursor: "pointer" }}
                  >
                    + Add More
                  </span>
                </div>
              )}
              <button
                onClick={(e) => {
                  onAddQuestiuon(
                    e,
                    sortOrder,
                    setSortOrder,
                    editIndex,
                    setEditIndex
                  );
                }}
                className="mt-3 btn btn-primary"
              >
                {editIndex || editIndex == 0 ? "Update" : "Add"} Question
              </button>
            </div>
          </div>
        </Box>
      </div> */}
      {/* Question List */}
      <div
        style={{
          // maxHeight: "80vh",
          // overflow: "auto",
        }}
        className="col-xl-12"
      >
        <QuestionList
          questions={data?.questions}
          setQuestion={setQuestion}
          setQuestions={(questions) => setData({ ...data, questions })}
          setData={setData}
          setEditIndex={setEditIndex}
          setSortOrder={setSortOrder}
          question={question}
          onAddQuestiuon={onAddQuestiuon}
          sortOrder={sortOrder}
          editIndex={editIndex}
          onDublicateQuestiuon={onDublicateQuestiuon}
        />
      </div>
    </div>
  );
};

export default RiskAssesmentQuestions;
