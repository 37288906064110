import React from 'react'

const Pagination = ({ data, page, limit, setPage }) => {
    return (
        <div className='d-flex justify-content-end align-items-center gap-3 mt-2'>
            <button
                type="button"
                disabled={page == 1}
                className="btn btn-outline-primary"
                onClick={page == 1 ? () => { } : () => setPage(page - 1)}
            >{'<'}</button>
            <span>{page}</span>
            <button
                type="button"
                disabled={data?.length < limit}
                className="btn btn-outline-primary"
                onClick={data?.length < limit ? () => { } : () => setPage(page + 1)}
            >{'>'}</button>
        </div>
    )
}

export default Pagination