import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import api from "../app/api";
import useAlert from "./useAlert";

import { setRecords } from "../features/records/slice";

export default () => {
  const dispatch = useDispatch();
  const { records } = useSelector((state) => state.records);

  const { showAlert } = useAlert();

  // STATES
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const fetchRecords = async (params) => {
    setLoading(true);
    dispatch(setRecords([]));
    try {
      const response = await api.get(`/task/${params.isRiskAssesment == 'riskAssesment' ? 'recordsForRiskAssesment' : 'records'}`, { params });
      dispatch(setRecords(response.data));
      setLoading(false);
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      //(message)
      showAlert(message, "danger");
      setLoading(false);
    }
  };

  return {
    page,
    limit,
    loading,
    records,
    setPage,
    fetchRecords,
  };
};
