import {
  Text,
  Font,
  Document,
  Page,
  StyleSheet,
  View,
  Link,
  Image,
} from "@react-pdf/renderer";
import imagePlaceholder from "../../assets/logo.png";
import { keys } from "../../config/keys";
import { findRiskRating } from "../../config/functions";

// Font.register({
//   family: "Nunito",
//   src: require("../../assets/fonts/NunitoSans-Bold.ttf"),
// });
Font.register({
  family: "Nunito",
  fonts: [
    {
      src: require("../../assets/fonts/NunitoSans-Regular.ttf"),
      fontWeight: "normal",
    },
    {
      src: require("../../assets/fonts/NunitoSans-Bold.ttf"),
      fontWeight: "bold",
    },
    {
      src: require("../../assets/fonts/NunitoSans-Light.ttf"),
      fontWeight: "light",
    },
    {
      src: require("../../assets/fonts/NunitoSans-SemiBold.ttf"),
      fontWeight: "600",
    },
    // Add more font weights as needed
  ],
});
const styles = StyleSheet.create({
  image: {
    width: 20,
    height: 20,
    marginBottom: 10,
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    backgroundColor: "white",
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Nunito",
    fontWeight: 'bold',
    color: "#012970",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Nunito",
    fontWeight: 'bold'
  },
  card: {
    backgroundColor: "white",
    borderRadius: 10,
    padding: 20,
  },
  container: {
    // borderRight: "0.4px solid #00000012",
    // borderLeft: "0.4px solid #00000012",
  },
  label: {
    fontSize: 11,
    padding: 10,
    paddingBottom: 0,
    textAlign: "justify",
    color: "rgba(0,0,0,0.6)",
    fontFamily: 'Nunito',
    fontWeight: "light",
  },
  question: {
    fontSize: 14,
    padding: 10,
    paddingTop: "0",
    paddingBottom: "0",
    textAlign: "justify",
    fontFamily: "Nunito",
    fontWeight: 'normal'
  },
  answer: {
    fontSize: 14,
    padding: 10,
    paddingTop: "0",
    textAlign: "justify",
    fontFamily: "Nunito",
    fontWeight: 'bold'
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
    fontFamily: "Nunito",
    fontWeight: 'light'
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  pageDate: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "right",
    color: "grey",
    marginRight: 35,
  },
});
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const PDF = ({ data }) => {
  const image = data?.companyID?.imageURL
    ? `${keys.BASE_LOCAL_URL}/${data?.companyID?.imageURL}`
    : imagePlaceholder;
  let calculatedRiskRating = findRiskRating(data)
  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.header} fixed>
          CompCheck.io
        </Text>
        <Text style={styles.header} fixed>
          {data.companyID?.name}
        </Text>
        <View
          style={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            src={image}
            style={{ width: 100, height: 100, marginBottom: 10 }}
          />
          <Text style={styles.title}>{data?.title}</Text>
        </View>
        <Text style={styles.author}>{data?.checklistFrequency}</Text>
        <View style={styles.card}>
          <View>
            {data?.questions.map((item, index) => (
              <>
              {item.type != 'likelihood' ? <View key={index} style={styles.container}>
                {(item.type != "statement" && item.type != "statement/Instruction") && (
                  <Text style={styles.label}>Question</Text>
                )}
                <Text
                  style={{
                    ...styles.question,
                    ...((item.type == "statement" || item.type == "statement/Instruction") && {
                      paddingTop: "10",
                      fontWeight: 'normal'
                    }),
                  }}
                >
                  {item.question}
                </Text>
                {(item.type != "statement" && item.type != "statement/Instruction") && (
                  <Text style={styles.label}>Answer</Text>
                )}
                {(item.type != "statement" && item.type != "statement/Instruction") &&
                  (item.answer?.includes("uploads") ? (
                    <Link
                      src={`${keys.BASE_LOCAL_URL}/${item.answer}`}
                      style={styles.answer}
                    >
                      View
                    </Link>
                  ) : (
                    <Text style={styles.answer}>{item.type == 'high/medium/low' || item.type == 'yes/no/na' ? capitalizeFirstLetter(item.answer) : item.answer}</Text>
                  ))}
              </View> : <>
              <View key={index} style={styles.container}>
                {(item.type != "statement" && item.type != "statement/Instruction") && (
                  <Text style={styles.label}>Question</Text>
                )}
                <Text
                  style={{
                    ...styles.question,
                    ...((item.type != "statement" && item.type != "statement/Instruction") && {
                      paddingTop: "10",
                      fontWeight: 'normal'
                    }),
                  }}
                >
                  {item.question}
                </Text>
                {(item.type != "statement" && item.type != "statement/Instruction") && (
                  <Text style={styles.label}>Answer</Text>
                )}
                {(item.type != "statement" && item.type != "statement/Instruction") &&
                  (item.answer?.includes("uploads") ? (
                    <Link
                      src={`${keys.BASE_LOCAL_URL}/${item.answer}`}
                      style={styles.answer}
                    >
                      View
                    </Link>
                  ) : (
                    <Text style={styles.answer}>{item.type == 'high/medium/low' || item.type == 'yes/no/na' ? capitalizeFirstLetter(item.answer) : item.answer}</Text>
                  ))}
              </View>

              <View key={index} style={styles.container}>
                {(item.type != "statement" && item.type != "statement/Instruction") && (
                  <Text style={styles.label}>Question</Text>
                )}
                <Text
                  style={{
                    ...styles.question,
                    ...((item.type != "statement" && item.type != "statement/Instruction") && {
                      paddingTop: "10",
                      fontWeight: 'normal'
                    }),
                  }}
                >
                  {'Risk Rating (Calculated)'}
                </Text>
                {(item.type != "statement" && item.type != "statement/Instruction") && (
                  <Text style={styles.label}>Answer</Text>
                )}
                {(item.type != "statement" && item.type != "statement/Instruction") &&
                  (item.answer?.includes("uploads") ? (
                    <Link
                      src={`${keys.BASE_LOCAL_URL}/${item.answer}`}
                      style={styles.answer}
                    >
                      View
                    </Link>
                  ) : (
                    <Text style={styles.answer}>{calculatedRiskRating}</Text>
                  ))}
              </View>
              </>}
              </>
            ))}
          </View>
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
        <Text
          style={styles.pageDate}
          render={() => new Date(data?.timestamp).toLocaleDateString("en-GB")}
          fixed
        />
      </Page>
    </Document>
  );
};

export default PDF;
