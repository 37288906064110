import { PDFDownloadLink } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import api from "../../app/api";
import BreadCrumb from "../../components/BreadCrumb";
import Table from "../../components/Table";
import PDF from "../policies/pdf";
import Loader from "../../components/Loader";
import FormField from "../../components/FormField";
import { Box, Typography } from "@mui/material";
import SubmitButton from "../../components/SubmitButton";
import useAlert from "../../hooks/useAlert";
export default function Default() {
  const location = useLocation();
  const { showAlert } = useAlert();
  const queryParams = new URLSearchParams(location.search);
  const [loading, setloading] = useState(false);
  const [agree, setAgree] = useState(false);
  const [agree1, setAgree1] = useState(false);
  const id = queryParams.get("id");
  const isUser = queryParams.get("isUser");
  const userID = queryParams.get("userID");
  function customDecode(encodedEmail) {
    if (!isUser) {
      return encodedEmail
        ?.split("-")
        ?.map((code) => String.fromCharCode(code - 5))
        .join("");
    }
  }
  const encodedEmail = queryParams.get("email");
  const email = customDecode(encodedEmail);
  const name = queryParams.get("name");
  const [data, setData] = useState([]);
  const [isSubmitted, setisSubmitted] = useState(false);
  const [shareable, setShareable] = useState({});
  const [signatureHandler, setsignatureHandler] = useState("");
  const labels = [
    "#",
    shareable.forFeature == "policy" ? "Title" : "Name",
    shareable.forFeature == "policy" ? "Last Reviewed" : shareable.forFeature == "management system" ? "Created at" :  "Size",
    "File",
  ].filter((item) => item);
  const getData = async () => {
    try {
      const response = await api.get(`/share/get?id=${id}&isUser=${isUser}`);
      if (response.data?.status) {
        setShareable(response.data.result);
        const dataa = response.data?.result?.data
          ? response.data?.result?.data.map((item, index) => {
            return {
              data: item,
              fields: [
                index + 1,
                item?.title || item.name,
                item?.lastReviewed?.substring(0, 10) || item.size || item.createdAt?.substring(0,10),
                response.data.result?.forFeature == "policy" ? (
                  <PDFDownloadLink
                    document={<PDF data={item} />}
                    fileName={item?.title + `.pdf`}
                  >
                    {() => {
                      return (
                        <button className="btn btn-sm btn-success">
                          Open
                        </button>
                      );
                    }}
                  </PDFDownloadLink>
                ) : (
                  <a
                    href={`${process.env.REACT_APP_BASE_URL}/${item.url}`}
                    target="_blank"
                    className="btn btn-sm btn-success"
                  >
                    Open
                  </a>
                ),
              ].filter((it) => it),
            };
          })
          : [];
          let temp = response.data?.result?.allowedEmails?.filter(item => item.email == email)
         if (response.data?.result?.allowedEmails?.filter(item => item.email == email).length < 1 && !isUser) {
          showAlert("Restricted page!", "danger");
        } else {
          if (
            userID === localStorage.getItem("_id") ||
            response.data?.result?.allowedEmails?.filter(item => item.email == email).length > 0
          ) {
            setData(dataa);
          } else showAlert("Restricted page!", "danger");
        }
      } else {
        showAlert(response.data?.message, "danger");
      }
    } catch (error) {
    } finally {
      // setloading(false)
    }
  };
  const submitConcent = async () => {
    setloading(true);
    try {
      const formData = new FormData();
      formData.append("image", signatureHandler);
      formData.append("submittedEmail", email);
      formData.append("submittedName", name);
      formData.append("forFeature", shareable.forFeature);
      formData.append("shareableID", shareable._id);
      formData.append(
        "companyID",
        shareable.createdBy?.branchID
          ? shareable.createdBy?.companyID
          : shareable.createdBy?._id
      );
      formData.append("branchID", shareable.createdBy?.branchID);
      const response = await api.post(`/shareSubmissions/add`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.data?.status) {
        showAlert("Confirmation Submitted!", "success");
        setsignatureHandler(null);
        // setShareable({})
        setisSubmitted(true);
        email = "";
      } else {
        showAlert(response.data?.message, "danger");
      }
    } catch (error) {
    } finally {
      setloading();
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <div
      style={{
        border: "15px solid #0d6efd50",
        padding: "40px",
        minHeight: "100vh",
      }}
    >
      <BreadCrumb
        flow={[
          { label: "Share", to: "/share" },
          { label: "Home", to: "/" },
          { label: "Share", to: "/share" },
        ]}
      />
      {data.length > 0 ? (
        <>
          <Table data={data} labels={labels} />
          {!isUser && (
            <Box
              sx={{
                padding: "10px",
              }}
            >
              <FormField
                item={{
                  value: [signatureHandler],
                  setValue: (value, checkbox) => {
                    setsignatureHandler(value);
                  },
                  type: "signature",
                }}
                disabled={false}
              />
            </Box>
          )}
          {!isUser && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                // marginBottom: "20px",
              }}
            >
              <input
                type="checkbox"
                value={agree}
                onChange={(e) => {
                  setAgree(e.target?.checked);
                }}
              />
              <Typography style={{ marginLeft: "6px" }}>
                I confirm that I have received, read and understood the attached
                documents.
              </Typography>
            </Box>
          )}
          {!isUser && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <input
                type="checkbox"
                value={agree1}
                onChange={(e) => {
                  setAgree1(e.target?.checked);
                }}
              />
              <Typography style={{ marginLeft: "6px" }}>
                I also understand that if I require any clarifications, I may
                contact my designated Manager.
              </Typography>
            </Box>
          )}
          {!isUser && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                marginTop: "15px",
              }}
              className="d-flex justify-between"
            >
              <SubmitButton
                title={"Submit"}
                loading={loading}
                onClick={() => {
                  // setData({ ...data, description: data.aiResponse });
                  submitConcent();
                }}
                color={"success"}
                loaderColor={"white"}
                disabled={
                  signatureHandler == "" || !agree || !agree1 || isSubmitted
                }
              />
            </Box>
          )}
        </>
      ) : (
        <Loader loading={true} center />
      )}
    </div>
  );
}
