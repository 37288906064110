import { useState } from "react";
import "react-quill/dist/quill.snow.css";
import api from "../app/api";
import useAlert from "./useAlert";
import { useDispatch } from "react-redux";
import { setEmail, setEmailGroup } from "../features/emails/slice";
import { DeleteAlert } from "../components/Swal Alert/index";
export default () => {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [isUpdate, setisUpdate] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [data, setdata] = useState({
    newEmail: "",
    name:"",
    importedEmails: [],
  });
  const [newGroup, setNewGroup] = useState("");
  const [pageState, setpageState] = useState({
    state: "group",
    group: "",
  });
  const { showAlert } = useAlert();
  const getEmails = async (group) => {
    setloading(true);
    setpageState({
      state: "email",
      group: group,
    });
    try {
      const response = await api.get(`/email/getByGroup?group=${group}`);
      if (response.data?.status) {
        // setEmail(response.data?.result);
        dispatch(setEmail(response.data?.result));
      } else {
        // setEmail([]);
        dispatch(setEmail([]));
      }
    } catch (error) {
      showAlert(error.message, "danger");
    } finally {
      setloading(false);
    }
  };
  const getEmailGroups = async (filter) => {
    setloading(true);
    try {
      const response = await api.get(
        `/emailGroup/get?user=${localStorage.getItem("_id")}&branch=${filter.branch}&company=${filter.company}`
      );
      if (response.data?.status) {
        dispatch(setEmailGroup(response.data?.result));
        // setEmailGroups(response.data?.result);
      } else {
        dispatch(setEmailGroup([]));
      }
    } catch (error) {
      showAlert("error", error.message);
    } finally {
      setloading(false);
    }
  };
  const addEmailField = {
    value: data.newEmail,
    setValue: (value) => {
      setdata({ ...data, newEmail: value });
    },
    placeholder: "Enter Email",
    type: "text",
    id: "newEmail",
    disabled: data.importedEmails?.length > 0,
  };
  const addNameField = {
    value: data.name,
    setValue: (value) => {
      setdata({ ...data, name: value });
    },
    style:{marginLeft:'8px'},
    placeholder: "Enter Name",
    type: "text",
    id: "name",
    disabled: data.importedEmails?.length > 0,
  };
  const addGroupField = {
    value: newGroup,
    setValue: (value) => {
      setNewGroup(value);
    },
    placeholder: "Enter Group Name",
    type: "text",
    id: "newGroup",
  };
  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  const handleCsvFile = (data) => {
    // Assuming the first row contains headers, find the index of 'email' column
    // Extract emailGroups from each row (starting from the second row)
    const extractedEmails = data
      .filter((row) => row.email && isValidEmail(row.email) && row.name)
      .map((item) => {
        return {
          email:item.email,
          name:item.name
        }
      });
    // Update state with extracted emailGroups
    setdata((prevState) => {
      let newState = { ...prevState };
      newState.importedEmails = extractedEmails;
      newState.newEmail = "";
      return newState;
    });
  };
  const handleRemoveEmail = (item) => {
    setdata((prevState) => {
      let newState = { ...prevState };
      let prevImportedEmails = newState.importedEmails;
      let updatedImportedEmails = prevImportedEmails.filter(
        (itemm) => itemm != item
      );
      newState.importedEmails = updatedImportedEmails;
      return newState;
    });
  };
  const addEmails = async () => {
    setAddLoading(true);
    try {
      if (data.importedEmails.length < 1) {
        if (!isValidEmail(data.newEmail)) {
          showAlert("Invalid Email!", "danger");
          return;
        }
      }
      const response = await api.post("/email/add", {
        user: localStorage.getItem("_id"),
        email:
          data.importedEmails.length > 0
            ? data.importedEmails
            : [{email: data.newEmail, name:data.name}],
        group: pageState.group,
      });
      console.log(response)
      if (response.data.status) {
        showAlert("Email Added Successfully", "success");
        setdata({
          newEmail: "",
          importedEmails: [],
        });
        getEmails(pageState.group);
      } else {
        showAlert(response.data?.message, "danger");
      }
    } catch (error) {
    } finally {
      setAddLoading(false);
    }
  };
  const addGroup = async () => {
    setAddLoading(true);
    try {
      const response = await api.post("/emailGroup/add", {
        user: localStorage.getItem("_id"),
        name: newGroup,
      });
      if (response.data?.status) {
        showAlert("Group Added Successfully", "success");
        setNewGroup("");
        getEmailGroups();
      } else {
        showAlert(response.data?.message, "danger");
      }
    } catch (error) {
    } finally {
      setAddLoading(false);
    }
  };
  const deleteEmail = async (id) => {
    if (!(await DeleteAlert())) return;
    setloading(true);
    try {
      const response = await api.delete(`/email/delete?_id=${id}`);
      if (response.data?.status) {
        showAlert(response.data?.message, "success");
        getEmails(pageState.group);
      } else {
        showAlert(response.data?.message, "danger");
      }
    } catch (error) {
      setloading(false);
    } finally {
      // setloading(false);
    }
  };
  const deleteGroup = async (id) => {
    if (!(await DeleteAlert())) return;
    setloading(true);
    try {
      const response = await api.delete(`/emailGroup/delete?_id=${id}`);
      if (response.data?.status) {
        showAlert(response.data?.message, "success");
        getEmailGroups();
      } else {
        showAlert(response.data?.message, "danger");
      }
    } catch (error) {
      setloading(false);
    } finally {
      // setloading(false);
    }
  };
  const handleGroupUpdate = async (item) => {
    setisUpdate(true);
    setNewGroup(item.name);
    setSelectedItem(item);
  };
  const updateGroup = async () => {
    try {
      setAddLoading(true);
      const response = await api.put("/emailGroup/update", {
        _id: selectedItem._id,
        name: newGroup,
      });
      if (response.data?.status) {
        showAlert("Group Updated Successfully", "success");
        setNewGroup("");
        getEmailGroups();
      } else {
        showAlert(response.data?.message, "danger");
      }
    } catch (error) {
    } finally {
      setisUpdate(false);
      setNewGroup("");
      setAddLoading(false);
    }
  };
  const handleEmailUpdate = async (item) => {
    setisUpdate(true);
    setdata({ newEmail: item.email, name:item.name, importedEmails: [] });
    setSelectedItem(item);
  };
  const updateEmail = async () => {
    if (!isValidEmail(data.newEmail)) {
      showAlert("Invalid Email!", "danger");
      return;
    }
    try {
      setAddLoading(true);
      const response = await api.put("/email/update", {
        _id: selectedItem._id,
        email: data.newEmail,
        name: data.name,
        user:localStorage.getItem('_id'),
        group:pageState.group
      });
      if (response.data?.status) {
        showAlert("Email Updated Successfully", "success");
        getEmails(pageState.group);
        setisUpdate(false);
      setdata({ newEmail: "", importedEmails: [] });
      } else {
        showAlert(response.data?.message, "danger");
      }
    } catch (error) {
    } finally {
      
      setAddLoading(false);
    }
  };
  const handleBackClick = () => {
    dispatch(setEmail([]));
    setpageState({
      state: "group",
      group: "",
    });
  };
  return {
    // emailGroups,
    // setEmailGroups,
    // email,
    addNameField,
    loading,
    setloading,
    addLoading,
    setAddLoading,
    data,
    setdata,
    newGroup,
    setNewGroup,
    pageState,
    setpageState,
    getEmails,
    getEmailGroups,
    addEmailField,
    addGroupField,
    isValidEmail,
    handleCsvFile,
    handleRemoveEmail,
    addEmails,
    addGroup,
    handleBackClick,
    deleteGroup,
    deleteEmail,
    isUpdate,
    setisUpdate,
    handleGroupUpdate,
    updateGroup,
    handleEmailUpdate,
    updateEmail,
  };
};
