import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import FormField from "../../components/FormField";
import Screen from "../../components/Screen";
import useEmails from "../../hooks/useEmails";
import FilesList from "./fileList";
import GroupView from "./groupView";
import EmailView from "./emailView";
import useAlert from "../../hooks/useAlert";
import SubmitButton from "../../components/SubmitButton";
import { PoliciesContext } from "../../hooks/policiesContext";
import EmptyData from "../../components/EmptyData";
import api from "../../app/api";
import { useLocation, useNavigate } from "react-router-dom";
export default function Default() {
  const [loading, setloading] = useState(false);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const { selectedForShare, removeAllFromShare } = useContext(PoliciesContext);
  const location = useLocation();
  const navigator = useNavigate();
  const [manualEmail, setManualEmail] = useState("");
  const { showAlert } = useAlert();
  const { getEmails, getEmailGroups, isValidEmail } = useEmails();
  const handleRemoveEmail = (item) => {
    setSelectedEmails((prevState) => {
      let newState = [...prevState];
      let updatedImportedEmails = newState.filter((itemm) => itemm != item);
      return updatedImportedEmails;
    });
  };
  const handleAddEmail = (item) => {
    if (!isValidEmail(item.email)) {
      showAlert("Invalid Email!", "danger");
      return;
    }
    if (selectedEmails.includes(item)) {
      showAlert("Email Already Added", "danger");
      return;
    }
    setSelectedEmails((prevState) => {
      let newState = [...prevState];
      newState.push({email:item.email, name: item.name ? item.name : ''});
      return newState;
    });
    setManualEmail("");
  };
  const handleShare = async () => {
    setloading("new");
    try {
      const response = await api.post("/share/add", {
        allowedEmails: selectedEmails,
        shareable: selectedForShare,
        forFeature: location.state?.isDocument ? "document" : location.state?.isManagementSystem ? "management system" : "policy",
        createdBy: localStorage.getItem("_id"),
      });
      if (response.data?.status == true) {
        navigator("/dashboard");
        showAlert("Email Sent Successfully!", "success");
        setSelectedEmails([]);
        removeAllFromShare();
        setManualEmail("");
      } else {
        showAlert(response.data?.message, "danger");
      }
    } catch (error) {
      showAlert(error?.message, "danger");
    } finally {
      setloading(null);
    }
  };
  const addGroupField = {
    value: manualEmail,
    setValue: (value) => {
      setManualEmail(value);
    },
    placeholder: "Enter Email...",
    type: "text",
    id: "manualEmail",
  };
  useEffect(() => {
    getEmailGroups();
  }, []);

  return (
    <Screen
      loading={loading}
      flow={[
        { label: "Share", to: "/share" },
        { label: "Home", to: "/" },
        { label: "Share", to: "/share" },
      ]}
    >
      <EmptyData data={selectedForShare}>
        <div className="d-flex justify-content-end mb-3 gap-3">
          {
            <>
              <SubmitButton
                title={"Send Mail"}
                loading={loading == "new"}
                onClick={handleShare}
                color={"outline-primary"}
                loaderColor={"white"}
                disabled={selectedEmails.length < 1}
              />
            </>
          }
        </div>
        <div>
          <div className="row">
            <FilesList />
            <div className="col-md-12 col-lg-6">
              <div
                style={{
                  height: "300px",
                  overflow: "auto",
                }}
                className="card"
              >
                <div className="card-body">
                  <div className="col-md-12 col-12">
                    <h5 className="card-title">Selected Emails</h5>
                    <div className="d-flex">
                      <FormField item={addGroupField} />
                      <SubmitButton
                        style={{ marginLeft: "15px" }}
                        title={"Add"}
                        loading={loading}
                        onClick={() => handleAddEmail({email:manualEmail, name:'a'})}
                        color={"success"}
                        loaderColor={"white"}
                        disabled={manualEmail == ""}
                      />
                    </div>
                    {selectedEmails?.length > 0 && (
                      <Box
                        sx={{
                          maxHeight: "150px",
                          overflow: "auto",
                          marginTop: "15px",
                          border: "1px solid #00000012",
                          padding: "5px",
                        }}
                      >
                        {selectedEmails?.map((item) => (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              backgroundColor: "#00000012",
                              padding: "3px 6px",
                              margin: "5px 0px",
                              borderRadius: "8px",
                            }}
                          >
                            <Typography>{item.email}</Typography>
                            <Typography
                              sx={{
                                cursor: "pointer",
                              }}
                              onClick={() => handleRemoveEmail(item)}
                            >
                              x
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <GroupView getEmails={getEmails} />
            <EmailView
              handleAddEmail={handleAddEmail}
              selectedEmails={selectedEmails}
            />
          </div>
        </div>
      </EmptyData>
    </Screen>
  );
}
