import { Text, Font, Document, Page, StyleSheet, View, Link, Image } from "@react-pdf/renderer";
import imagePlaceholder from '../../assets/logo.png';
import { keys } from "../../config/keys";

Font.register({
  family: "Nunito",
  fonts: [
    {
      src: require("../../assets/fonts/NunitoSans-Regular.ttf"),
      fontWeight: "normal",
    },
    {
      src: require("../../assets/fonts/NunitoSans-Bold.ttf"),
      fontWeight: "bold",
    },
    {
      src: require("../../assets/fonts/NunitoSans-Light.ttf"),
      fontWeight: "light",
    },
    {
      src: require("../../assets/fonts/NunitoSans-SemiBold.ttf"),
      fontWeight: "600",
    },
    // Add more font weights as needed
  ],
});

const styles = StyleSheet.create({
  image: {
    width: 20,
    height: 20,
    marginBottom: 10
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    backgroundColor: 'white',
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Nunito',
    fontWeight:'bold',
    color: '#012970'
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
    fontFamily: 'Nunito',
    fontWeight:'normal'
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Nunito',
    fontWeight:'bold',
  },
  card: {
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 20,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  question: {
    flex: 1,
    fontSize: 14,
    borderRightWidth: 0.4,
    padding: 10,
    textAlign: 'justify',
    fontFamily: 'Nunito',
    fontWeight:'bold',
  },
  answer: {
    flex: 2,
    fontSize: 14,
    padding: 10,
    textAlign: 'justify',
    fontFamily: 'Nunito',
    fontWeight:'normal',
    textAlign: 'right'
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
    fontFamily: "Nunito",
    fontWeight:'light'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  pageDate: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'right',
    color: 'grey',
    marginRight: 35
  },
});

const PDF = ({ data }) => {
  const image =
    (data?.companyID?.imageURL)
      ? `${keys.BASE_LOCAL_URL}/${data?.companyID?.imageURL}`
      : imagePlaceholder
    ;

  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.header} fixed>
          CompCheck
        </Text>
        <Text style={styles.header} fixed>
          {data.companyID.name}
        </Text>
        <View style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <Image src={image} style={{ width: 50, height: 50, marginBottom: 10 }} />
          <Text style={styles.title}>{data?.title}</Text>
        </View>
        <Text style={styles.author}>{data?.checklistFrequency}</Text>
        <View style={styles.card}>
          {/* <Text style={{ textAlign: 'center', marginBottom: 10, color: getStatus(data?.status) }}>{data?.status}</Text> */}
          <View style={styles.container}>
            <Text style={{ color: '#899bbd', borderWidth: 0.4, flex: 1, padding: 10, fontFamily:'Nunito', fontWeight:'Normal' }}>Questions</Text>
            <Text style={{ color: '#899bbd', borderWidth: 0.4, flex: 2, padding: 10, textAlign: 'right', fontFamily:'Nunito', fontWeight:'Normal' }}>Answers</Text>
          </View>
          <View>
            {
              data?.questions.map((item, index) => (
                
                <View key={index} style={styles.container}>
                  <Text style={styles.question}>{item.question}</Text>
                  {
                    item.answer?.includes('uploads')
                      ? <Link src={`${keys.BASE_LOCAL_URL}/${item.answer}`} style={styles.answer}>View</Link>
                      : <Text style={styles.answer}>{item.answer}</Text>
                  }
                </View>
              ))
            }
          </View>
        </View>

        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
        <Text style={styles.pageDate} render={() => new Date(data?.timestamp).toLocaleDateString('en-GB')} fixed />
      </Page>
    </Document>
  )
};

export default PDF;
