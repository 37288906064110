import React, { useContext, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Loader from "./Loader";
import BreadCrumb from "./BreadCrumb";
import CompanyFilter from "./CompanyFilter";
import useAuth from "../hooks/useAuth";
import useNotification from "../hooks/useNotification";
import appContext from "../app/contextState/context";
import Notification from "./notificationPopup";
import { Button } from "react-bootstrap";
import { Box, Typography } from "@mui/material";
import { PoliciesContext } from "../hooks/policiesContext";
import ManagementSystemFilter from "./managementSystemFilter";
const Screen = ({
  loading,
  children,
  filter,
  setFilter,
  flow,
  showBranchFilter,
  dontShowCompany,
  showShare,
  allowed,
  selectedCategories,
  setSelectedCategories,
  selectedNature,
  setselectedNature,
  accurate,
  setAccurate,
}) => {
  const { pathname } = useLocation();
  const navigator = useNavigate();
  const appState = useContext(appContext);
  const { onClick } = useNotification();
  const { selectedForShare } = useContext(PoliciesContext);
  const {
    showNotification,
    setshowNotification,
    notificationData,
    selectedChecklists,
    addMultipleChecklistsAdd,
    addMultipleRiskAssesmentssAdd,
  } = appState;
  const {
    user,
    isAdmin,
    isGuest,
    isManager,
    isSupervisor,
    isEmployee,
    onUser,
  } = useAuth();
  const adminOnly = ["/companies", "/categories", "/natures", "/admins"];
  const employeeOnly = isGuest
    ? ["/dashboard", "/records", "/training"]
    : [
        "/dashboard",
        "/training",
        "/task",
        "/checklists",
        "/tasks",
        "/tasks/Daily",
        "/tasks/Weekly",
        "/tasks/Annual",
        "/records",
        "/broadcast-details",

        ...(isSupervisor
          ? []
          : [
              "/documents",
              "/policy",
              "/policyInput",
              "/policies-options",
              "/share",
              "/emailConfirmations",
            ]),
        ...(!isManager
          ? []
          : [
              "/checklists",
              "/new-checklist",
              "/profile",
              "/inbox",
              "/risk-assesments",
              "/new-risk-assesment",
              "/email",
            ]),
      ];
  const noAccess =
    (!isAdmin && adminOnly.includes(pathname)) ||
    (isEmployee && !employeeOnly.includes(pathname)) ||
    user.status == false;
  useEffect(() => {
    onUser();
  }, []);
  return noAccess ? (
    <Navigate to={"*"} />
  ) : (
    <div style={{ position: "relative" }}>
      <Header />
      <Sidebar />
      <Notification
        showNotification={showNotification}
        setshowNotification={setshowNotification}
        notificationData={notificationData}
        onClick={() => {
          onClick(notificationData);
          setshowNotification(false);
        }}
      />
      {allowed && (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            position: "absolute",
            backgroundColor: "rgba(0,0,0,0.2)",
            zIndex: "99",
            backdropFilter: "blur(5px)",
          }}
        >
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Typography sx={{ fontSize: "24px", color: "black" }}>
              Your subscription does not include this feature.
            </Typography>
          </Box>
        </Box>
      )}
      <main
        id="main"
        style={{
          minHeight: "85vh",
        }}
        className="main"
      >
        <div className="d-flex w-100 justify-content-between align-items-center">
          <BreadCrumb flow={flow} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            {filter ? (
              <CompanyFilter
                dontShowCompany={dontShowCompany}
                filter={filter}
                setFilter={setFilter}
                showBranch={showBranchFilter}
              />
            ) : null}
            {selectedCategories ? (
              <ManagementSystemFilter
                selectedCategories={selectedCategories}
                setSelectedCategories={setSelectedCategories}
                selectedNature={selectedNature}
                setselectedNature={setselectedNature}
                accurate={accurate}
                setAccurate={setAccurate}
              />
            ) : null}
            {dontShowCompany && (
              <Button
                style={{
                  marginRight: "20px",
                }}
                disabled={selectedChecklists.length == 0}
                onClick={
                  pathname != "/risk-assesments"
                    ? addMultipleChecklistsAdd
                    : addMultipleRiskAssesmentssAdd
                }
              >
                Add
              </Button>
            )}
            {showShare && (
              <Button
                style={{
                  marginRight: "20px",
                }}
                disabled={selectedForShare.length == 0}
                onClick={() => {
                  navigator("/share", {
                    state: {
                      selectedForShare,
                      isDocument: flow[0]?.to?.includes("documents"),
                    },
                  });
                }}
              >
                Share
              </Button>
            )}
          </Box>
        </div>
        <Loader loading={loading} center>
          {children}
        </Loader>
      </main>
      <Footer />
    </div>
  );
};

export default Screen;
