import { createContext, useContext } from "react";

import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import api from "../app/api";
import useAlert from "./useAlert";
import { DeleteAlert, UpdateAlert } from "../components/Swal Alert/index";

import {
  setAudit,
  addAudit,
  updateAudit,
  deleteAudit,
} from "../features/audit/slice";
import appContext from "../app/contextState/context";
import { capitalFirstLetter } from "../config/functions";

const AuditContext = createContext();

function AuditState(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auditCollection } = useSelector((state) => state.audit);
  const { categories } = useSelector((state) => state.category);

  const { showAlert } = useAlert();

  // STATES
  const [questionTypes, setQuestionTypes] = useState();
  const { loading, setLoading } = useContext(appContext);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  // New Audit State
  const [data, setData] = useState({
    title: "",
    auditFrequency: "Daily",
    notificationFrequency: {
      times: [Date.now()],
      date: Date.now(),
      day: "",
    },
    questions: [],
    categoryID: categories[0]?._id,
  });
  const [question, setQuestion] = useState({
    question: "",
    type: "",
    hintText: "",
    options: [],
    yes: "",
    no: "",
    required: false,
  });
  const onDublicateQuestiuon = (question, index) => {
    var questions = [...data.questions];
    if (index == undefined) {
      questions.push(question);
    } else {
      questions = [
        ...questions.slice(0, index + 1),
        question,
        ...questions.slice(index + 1),
      ];
    }

    setData({ ...data, questions });
  };
  const fetchAudits = async (params) => {
    setLoading("fetch");
    try {
      const response = await api.get(`/audit`, { params });
      dispatch(setAudit(response.data));
      setLoading(false);
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      showAlert(capitalFirstLetter(message), "danger");
      setLoading(false);
    }
  };
  const fetchAuditByCompanyId = async (id) => {
    setLoading(true);
    try {
      if (!id) return setLoading(false);
      const response = await api.get(`/audit/${id}`);
      dispatch(setAudit(response.data));
      setLoading(false);
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setLoading(false);
      showAlert(capitalFirstLetter(message), "danger");
    }
  };
  const fetchQuestionTypes = async () => {
    setLoading(true);
    try {
      const response = await api.get("/audit/question/types");
      setQuestionTypes(response.data.questionTypes);
      setLoading(false);
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      showAlert(capitalFirstLetter(message), "danger");
      setLoading(false);
    }
  };

  const onAddQuestiuon = (
    e,
    sortIndex,
    setSortIndex,
    editIndex,
    setEditIndex
  ) => {
    e.preventDefault();
    var questions = [...data.questions];
    if (editIndex || editIndex == 0) {
      questions.splice(editIndex, 1);
      setEditIndex(undefined);
    }
    if (sortIndex) {
      questions.splice(sortIndex - 1, 0, question);
      setSortIndex(questions.length + 1);
    } else {
      questions.push(question);
    }
    setData({ ...data, questions });
    setQuestion({
      question: "",
      type: "",
      hintText: "",
      options: [],
      yes: "",
      no: "",
      required: false,
    });
  };

  const newAudit = async () => {
    setLoading("new");
    try {
      const response = await api.post("/audit/new", { ...data });
      dispatch(addAudit(response.data));
      setLoading(false);
      setData({
        title: "",
        auditFrequency: "Daily",
        notificationFrequency: "",
        questions: [],
        category: categories[0],
      });
      showAlert("New audit added ", "success");
      navigate("/audits");
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      showAlert(capitalFirstLetter(message), "danger");
      setLoading(false);
    }
  };
  const changeAudit = async (status) => {
    if (!(await UpdateAlert())) return;
    setLoading(status ? status : "update");
    try {
      await api.put(
        `/audit/update/${data._id}`,
        status ? { status } : data
      );
      setData({ ...data, status });
      dispatch(updateAudit({ ...data, status }));
      setLoading(false);
      showAlert(
        status
          ? `Audit review: ${status}`
          : "Audit updated successfully",
        "success"
      );
      return true;
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setLoading(false);
      showAlert(capitalFirstLetter(message), "danger");
      setLoading(false);
    }
  };
  const removeAudit = async (audit) => {
    if (!(await DeleteAlert())) return;
    setLoading("delete");
    try {
      const response = await api.delete(
        `/audit/delete/${audit ? audit._id : data._id}`
      );
      dispatch(deleteAudit(response.data));
      setLoading(false);
      if (!audit) navigate("/audits");
      showAlert("Audit removed successfully", "success");
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      showAlert(message, "danger");
      setLoading(false);
    }
  };

  // Review
  const sendForReview = async () => {
    changeAudit("pending");
  };
  const approveReview = async () => {
    const allow = await changeAudit("approved");
    if (allow) newAudit();
  };
  const rejectReview = async () => {
    changeAudit("rejected");
  };
  return (
    <AuditContext.Provider
      value={{
        categories,
        auditCollection,
        page,
        limit,
        loading,
        data,
        question,
        questionTypes,
        setData,
        setQuestion,
        fetchAudits,
        fetchAuditByCompanyId,
        fetchQuestionTypes,
        setPage,
        onAddQuestiuon,
        newAudit,
        changeAudit,
        removeAudit,
        sendForReview,
        approveReview,
        rejectReview,
        onDublicateQuestiuon,
        setLimit
      }}
    >
      {props.children}
    </AuditContext.Provider>
  );
}
export { AuditContext, AuditState };
