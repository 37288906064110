import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";
import AppState from "./app/contextState/state";
// import { registerServiceWorker } from './serviceWorker'

import { store, persistor } from "./app/store";
import App from "./App";
import Alert from "./features/alert/Alert";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ChecklistState } from "./hooks/checklistContext";
import { PoliciesState } from "./hooks/policiesContext";
import { RiskAssesmentState } from "./hooks/riskAssesmentContext";
import { AuditState } from "./hooks/auditContext";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
const container = document.getElementById("root");
const root = createRoot(container);

const stripePromise = loadStripe("pk_live_51PonuY05tvmvcgMHDqoNzxd5oBdPShWqgQYmej7jOhPIv3YW8NLQeAmhfcFF9nC4XWzuory2kU5VYl9wYNIIAarp00YySWtANu");
// const stripePromise = loadStripe("pk_test_51PonuY05tvmvcgMHEGnkDaDRL7355LXayKH2ICvCna6HXSQDt0VCMR7YR5umI1YFzg1sb6f3dVuBwApX15qRsJpA007V4H1kHp");
root.render(
  // <React.StrictMode>
  <Elements stripe={stripePromise}>
    <Provider store={store}>
      <BrowserRouter>
        <AppState>
          <AuditState>
          <RiskAssesmentState>
            <ChecklistState>
              <PoliciesState>
                <DndProvider backend={HTML5Backend}>
                  <PersistGate loading={null} persistor={persistor}>
                    <App />
                    <Alert />
                  </PersistGate>
                </DndProvider>
              </PoliciesState>
            </ChecklistState>
          </RiskAssesmentState>
          </AuditState>
        </AppState>
      </BrowserRouter>
    </Provider>
    </Elements>
  // </React.StrictMode>
);
