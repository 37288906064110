import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";

import useCompanies from "../hooks/useCompanies";
import useTask from "../hooks/useTask";

import Loader from "./Loader";
import SubmitButton from "./SubmitButton";
import Table from "./Table";
import useManagementSystem from "../hooks/useManagementSystem";
import { PoliciesContext } from "../hooks/policiesContext";

const CompanyModal = ({ show, handleClose }) => {
  const labels = ["#", "Name", "Nature", "", "Actions"];
  const { loading, companies, fetchCompanies } = useCompanies();
  const { addForCompany,companyID } = useManagementSystem();
  const { selectedForShare } = useContext(PoliciesContext);
  useEffect(() => {
    if (show) fetchCompanies();
  }, [show]);

  const data = companies?.map((item, index) => {
    return {
      data: item,
      fields: [
        index + 1,
        item.name,
        item.nature?.name,
        item.position,
        <SubmitButton
          title={"Assign"}
          loading={companyID == item._id}
          color={"primary"}
          loaderColor={"white"}
          onClick={() => {
            addForCompany({ files: selectedForShare, companyID: item._id });
          }}
        />,
      ],
    };
  });

  return (
    <Modal style={{maxHeight:'80vh'}} show={show} centered onHide={handleClose}>
      <Modal.Header>
        <div className="row w-100">
          <Modal.Title className={"col-12"}>Assign Documents</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Loader loading={loading} center>
          <Table labels={labels} data={data} />
        </Loader>
      </Modal.Body>
    </Modal>
  );
};

export default CompanyModal;
