import { createSlice } from "@reduxjs/toolkit";

const adminSlice = createSlice({
  name: "admin",
  initialState: {
    admins: []
  },
  reducers: {
    setAdmin: (state, action) => {
      state.admins = action.payload;
    },
    updateAdmin: (state, action) => {
      state.admins = state.admins.map(item => item._id == action.payload._id ? action.payload : item);
    },
    deleteAdmin: (state, action) => {
      state.admins = state.admins.filter(item => item._id != action.payload._id);
      state.admins = state.admins.map(item => item.name == 'Default' ? { ...item, checklists: action.payload.checklists + item.checklists } : item);
    },
    addAdmin: (state, action) => {
      state.admins = [...state.admins, { ...action.payload, checklists: 0 }];
    },
  },
});

export const { setAdmin, updateAdmin, deleteAdmin, addAdmin } = adminSlice.actions;

export default adminSlice.reducer;
