import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import Screen from '../../components/Screen'
import FormField from '../../components/FormField';
import SubmitButton from '../../components/SubmitButton';
import ItemList from './ItemList';
import useCompanies from '../../hooks/useCompanies';
import useBroadcast from '../../hooks/useBroadcast';
import useAuth from '../../hooks/useAuth';

const BroadcastDetails = () => {
    const { isAdmin } = useAuth()
    const { loading, companies, branches, fetchBranches, fetchCompanies } = useCompanies();
    const {
        addLoading,
        data,
        setData,
        newBroadcast,
        fetchBroadcasts
    } = useBroadcast();
    const [search] = useSearchParams();
    const id = search.get('id');
    const viewOnly = search.get('viewOnly');
    const title = search.get('title');
    const message = search.get('description');
    const [company, setCompany] = useState();
    const disabled = id;

    useEffect(() => {
        if (viewOnly) {
            setData({ ...data, message: message, title: title })
        }
    }, [data])
    useEffect(() => {
        if (!viewOnly) {
            fetchBranches(company?._id)
        }
    }, [data])
    useEffect(() => {
        if (!viewOnly) {
            fetchCompanies({ limit: 0 })
        }
    }, [])

    useEffect(() => {
        if (!viewOnly) {
            if (id) fetchBroadcasts({ id })
        }
    }, [])
    return (
        <Screen flow={[
            { label: 'Broadcast Notifications', to: '/broadcast' },
            { label: 'Home', to: '/' },
            { label: 'Broadcast', to: '/broadcast' },
            { label: 'Details', to: '/broadcast-details' }
        ]}>
            {/* Notification Message */}
            <div className="col-md-12 col-12">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Message</h5>

                        <FormField item={{
                            label: 'Title',
                            value: data.title,
                            setValue: (value) => setData({ ...data, title: value })
                        }} disabled={disabled} />
                        <br />
                        <textarea
                            className="form-control"
                            type='text'
                            value={data.message}
                            onChange={(value) => setData({ ...data, message: value.target.value })}
                            disabled={disabled}
                            rows={5}
                        />

                        {(!viewOnly) && <SubmitButton
                            title={'Send'}
                            color={'primary mt-2'}
                            onClick={disabled ? null : (e) => {
                                e.preventDefault()
                                newBroadcast()
                            }}
                            loading={addLoading}
                            loaderColor={'white'}
                            disabled={disabled}
                        />}
                    </div>
                </div>
            </div>
            {/* List of Reciepnts */}
            {isAdmin && <div className="col-md-12 col-12">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Recipients</h5>
                        <div className='d-flex'>
                            <ItemList
                                all={data.all}
                                title={'Companies'}
                                data={companies}
                                selected={data.companies}
                                setSelected={(items) => {
                                    //(items)
                                    
                                    fetchBranches(company?._id)
                                    setData({
                                        ...data,
                                        companies: items?.map(comp => ({
                                            ...comp,
                                            all: true,
                                            branches: [],
                                            company: comp._id
                                        }))
                                    })
                                }}
                                setAll={(value) => setData({ ...data, all: value })}
                                setMore={setCompany}
                                disabled={disabled}
                            />
                            <ItemList
                                title={`Branches${company ? ` / ${company?.name}` : ''}`}
                                data={branches}
                                all={data.companies?.find(comp => comp._id == company?._id)?.all}
                                selected={data.companies?.find(item => item._id == company?._id)?.branches}
                                allDisabled={!company || data.all}
                                disabled={disabled}
                                loading={loading}

                                setAll={(value) => setData({
                                    ...data,
                                    companies: data.companies?.map(comp => (comp._id != company?._id ? comp : {
                                        ...comp,
                                        all: value
                                    }))
                                })}
                                setSelected={(items) => setData({
                                    ...data,
                                    companies: data.companies?.map(comp => (comp._id != company?._id ? comp : {
                                        ...comp,
                                        branches: items?.map(branch => ({
                                            ...branch,
                                            branch: branch._id
                                        }))
                                    }))
                                })}
                            />
                        </div>
                    </div>
                </div>
            </div>}
        </Screen>
    )
}

export default BroadcastDetails