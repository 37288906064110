import React from 'react'
import { Link } from 'react-router-dom'

const Title = () => {
  return (
    <div className="col-lg-6 col-md-2 d-flex flex-column align-items-center justify-content-center">
      <div className="d-flex justify-content-center py-4">
        <Link to='/dashboard' className="logo d-flex align-items-center w-auto">
          <img src="assets/img/logo.png" alt="" />
          <span className="d-none d-lg-block">CompCheck</span>
        </Link>
      </div>
    </div>
  )
}

export default Title