import { Checkbox } from "@mui/material";
import React, { useContext } from "react";
import Loader from "../../components/Loader";
import Table from "../../components/Table";
import { keys } from "../../config/keys";
import { PoliciesContext } from "../../hooks/policiesContext";
import useAuth from "../../hooks/useAuth";
import useDocuments from "../../hooks/useDocuments";
import { useSelector } from "react-redux";
import useManagementSystem from "../../hooks/useManagementSystem";

const FilesList = ({
  setModalName,
  setShowAddModal,
  setDocData,
  page,
  limit,
  setPage,
  setSelectedCategories,
  setSelectedNature
}) => {
  const { msDocuments } = useSelector((state) => state.msDocuments);
  const { removeDocument, deleteLoading } = useManagementSystem();
  const { isAdmin } = useAuth();
  const {
    selectedForShare,
    addForShare,
    removeFromShare,
    removeAllFromShare,
    addAllToShare,
  } = useContext(PoliciesContext);
  const onView = (item) => {
    window.open(`${keys.BASE_LOCAL_URL}/${item.url}`, "_blank", "noreferrer");
  };
  const onHandleMoadal = (item) => {
    setModalName("Update Document");
    setShowAddModal(true);
    setDocData(item);
    setSelectedNature(item.natureID)
    setSelectedCategories(item.categoryID)
    
  };

  const labels = [
    isAdmin ? (
      <Checkbox
        checked={selectedForShare.length == msDocuments.length}
        onChange={async (e) => {
          if (e.target.checked) {
            addAllToShare(msDocuments ? msDocuments : []);
          } else {
            removeAllFromShare();
          }
        }}
      />
    ) : (
      "#"
    ),
    "Name",
    isAdmin ? "Company" : null,
    "Date Added",
    "File",
    isAdmin && "Actions",
  ].filter((item) => item);
  const data =
    msDocuments && msDocuments.length > 0
      ? msDocuments.map((item, index) => {
          return {
            data: item,
            fields: [
              // item?._id.substr(item?._id.length - 5),
              isAdmin ? (
                <Checkbox
                  checked={selectedForShare.includes(item)}
                  onChange={async (e) => {
                    if (e.target.checked) {
                      addForShare(item);
                    } else {
                      removeFromShare(item);
                    }
                  }}
                />
              ) : (
                item?._id.substr(item?._id.length - 5)
              ),
              item?.name,
              isAdmin ? <td>{item.companyID?.name}</td> : null,
              item?.createdAt?.substring(0, 10),
              <a
                // onClick={() => onView(item)}
                href={`${keys.BASE_LOCAL_URL}/${item.url}`}
                className="btn btn-sm btn-success"
              >
                Open
              </a>,
              isAdmin && (
                <div className="d-flex gap-3">
                  <i
                    className="bi bi-pencil-square text-primary"
                    style={{ cursor: "pointer" }}
                    onClick={() => onHandleMoadal(item)}
                  ></i>
                  {deleteLoading?._id == item?._id ? (
                    <div className="d-flex align-items-center justify-content-center ">
                      <Loader loading={deleteLoading} color={"red"} />
                    </div>
                  ) : (
                    <i
                      className="bi bi-trash text-danger"
                      style={{ cursor: "pointer" }}
                      onClick={() => removeDocument(item)}
                    ></i>
                  )}
                </div>
              ),
            ].filter((it) => it),
          };
        })
      : [];
  return (
    <>
      <Table
        labels={labels}
        data={data}
        page={page}
        limit={limit}
        setPage={setPage}
      />
    </>
  );
};

export default FilesList;
