import { useState } from "react";
import { setSubscriptions } from "../features/subscription/slice";
import { useDispatch } from "react-redux";
import api from "../app/api";
import useAuth from "./useAuth";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import useAlert from "./useAlert";
export default () => {
  const { showAlert } = useAlert();
  const [filter, setfilter] = useState({ company: "" });
  const [loading, setloading] = useState(false);
  const [stripeLoading, setStripeLoading] = useState(false);
  const [showPaymentModel, setShowPaymentModel] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const { onUser } = useAuth();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const getData = async (isPublic) => {
    setloading(true);
    try {
      const response = await api.get(
        `/subscription/get${isPublic ? "?isPublic=true" : ""}`
      );
      if (response.data?.status) {
        dispatch(setSubscriptions(response.data?.result));
      }
    } catch (error) {
    } finally {
      setloading(false);
    }
  };
  const updateSubscription = async (user, subscription) => {
    setloading(true);
    try {
      const response = await api.post(`/subscription/changeSubscription`, {
        user,
        subscription: subscription ? subscription : selectedSubscription._id,
      });
      onUser().then(() => setloading(false));
      if (response.data?.status) {
      }
    } catch (error) {
    } finally {
    }
  };
  const handleSubmit = async (event, user) => {
    event.preventDefault();
    try {
      if (!stripe || !elements) return;
      setStripeLoading(true);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });

      if (error) {
        setError(error.message);
        console.error("Error:", error);
      } else {
        // Send paymentMethod.id to your server

        const response = await api.post("/stripe/create-customer", {
          email: user.email,
          paymentMethodId: paymentMethod.id,
          userID: user._id,
        });
        if (!response.data?.status) {
          setError(response.data?.message);
          return;
        }
        const customer = response.data?.result;
        // Now create a subscription
        const subscriptionResponse = await api.post(
          "/stripe/create-subscription",
          {
            stripeCustomerId: customer.stripeCustomerId,
            priceId: selectedSubscription.priceID, // Replace with your price ID
          }
        );
        if (!subscriptionResponse.data?.status) {
          setError(response.data?.message);
          return;
        }
        const subscription = subscriptionResponse.data?.subscription;
        if (subscriptionResponse.data?.status) {
          const paymentIntent = subscription?.latest_invoice?.payment_intent;
          if (paymentIntent && paymentIntent.status === "requires_action") {
            // Handle 3D Secure authentication
            const {
              error: confirmError,
              paymentIntent: confirmedPaymentIntent,
            } = await stripe.confirmCardPayment(paymentIntent.client_secret);

            if (confirmError) {
              setError(confirmError.message);
              console.error("3D Secure Error:", confirmError);
              return;
            }

            if (confirmedPaymentIntent.status === "succeeded") {
              showAlert("Payment succeeded!", "success");
              setError(null);
              setShowPaymentModel(false);
              updateSubscription(user);
            } else {
              setError("Payment failed.");
            }
          } else if (subscription.status == "past_due") {
            const subscriptionResponse = await api.post(
              "/stripe/cancel-subscription",
              {
                subscriptionId: subscription.id,
              }
            );
            setError("Insufficient funds.");
          } else if (
            subscription?.status !== "incomplete" &&
            subscription?.status !== "incomplete_expired"
          ) {
            // No further action required
            showAlert("Payment succeeded!", "success");
            setError(null);
            setShowPaymentModel(false);
            updateSubscription(user);
          } else {
            setError("Insufficient funds.");
          }
        } else {
          setError(subscriptionResponse.data?.message);
        }
      }
    } catch (error) {
      showAlert(error.message, "danger");
    } finally {
      setStripeLoading(false);
    }
  };

  const makePayment = async (event, user) => {
    event.preventDefault();
    try {
      setStripeLoading(true);
      if (elements == null || stripe == null) {
        return;
      }
      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit();
      if (submitError?.message) {
        // Show error to your customer
        setError(submitError.message);
        return;
      }
      const response = await api.post("/stripe/create-payment-intent", {
        amount: selectedSubscription.cost * 100,
      });
      if (!response.data?.status) {
        showAlert(response.data?.message, "danger");
        return;
      }
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        response.data?.clientSecret,
        {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: user.name,
            },
          },
        }
      );

      if (error) {
        setError(error.message);
      } else if (paymentIntent.status === "succeeded") {
        showAlert("Payment succeeded!", "success");
        setError(null);
        setShowPaymentModel(false);
        updateSubscription(user);
      }
    } catch (error) {
      showAlert(error.message, "danger");
    } finally {
      setStripeLoading(false);
    }
  };
  return {
    getData,
    updateSubscription,
    filter,
    setfilter,
    loading,
    setloading,
    makePayment,
    error,
    setError,
    stripeLoading,
    showPaymentModel,
    setShowPaymentModel,
    selectedSubscription,
    setSelectedSubscription,
    handleSubmit,
  };
};
