import { Typography } from "@mui/material";
import React from "react";

export default function Default({itemKey,value}) {
    function camelToWords(camelCaseString) {
        // Convert camelCase string to words
        return camelCaseString?.replace(/([a-z])([A-Z])/g, '$1 $2')?.replace(/^./, str => str.toUpperCase()); // Capitalize the first letter
      }
  return (
    <>
    {typeof value != 'boolean' && <li
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        listStyle: "none",
        position: "relative", // Needed for pseudo-element positioning
        paddingLeft: "1.5em",
      }}
    >
      <span
        style={{
          position: "absolute",
          left: 0,
          top: "50%",
          transform: "translateY(-50%)",
          width: "0.5em",
          height: "0.5em",
          backgroundColor: "black", // Bullet color
          borderRadius: "50%",
        }}
      ></span>
      <Typography sx={{ fontSize: "13px" }}>{camelToWords(itemKey)}</Typography>
      <h6>
        {value != -1 ? value : "unlimited"}
      </h6>
    </li>}
    </>
  );
}
