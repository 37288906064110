import React, { useEffect } from "react";
import useCompanies from "../../hooks/useCompanies";
import Loader from "../../components/Loader";
import Table from "./Table";
import FormModal from "./FormModal";
import useAuth from "../../hooks/useAuth";

const Branches = ({ company, tab }) => {
  const labels = ["#", "Site Name", "Location", "Site Manager", "Actions"];
  const {
    permissions,
    managers,
    branches,
    loading,
    deleteLoading,
    addLoading,
    data,
    setData,
    fetchBranches,
    removeBranch,
    addBranch,
    changeBranch,
    fetchPermissions,
  } = useCompanies();
  const { user } = useAuth();
  useEffect(() => {
    if (company?._id && tab?.includes("branches")) fetchBranches(company._id);
    fetchPermissions({ companyID: company?._id });
  }, [company, tab]);

  const isUpdate = !!data?._id;

  const fields = [
    {
      placeholder: "Site Name",
      value: data?.name,
      setValue: (value) => setData({ ...data, name: value }),
      className: "col-md-6",
    },
    {
      placeholder: "Location",
      value: data?.location,
      setValue: (value) => setData({ ...data, location: value }),
      className: "col-md-6",
    },
    {
      placeholder: "Select Manager...",
      value: data?.managerID,
      setValue: (value) => setData({ ...data, managerID: value }),
      className: "col-md-12",
      type: "dropdown",
      options: managers.filter((item) => item.position == "Manager"),
    },
  ];

  const max = user?.subscriptionID?.additionalSites;
  const percentage = (branches?.length / max) * 100;
  console.log(user?.subscriptionID?.additionalSites);
  const color =
    percentage < 25
      ? "success"
      : percentage < 50
      ? "info"
      : percentage < 75
      ? "warning"
      : "danger";

  return (
    <div className="tab-pane fade pt-3" id="profile-manage-branches">
      <div className="table-container">
        <Loader loading={loading} center>
          <div className="progress">
            <div
              className={`progress-bar bg-${color}`}
              role="progressbar"
              style={{ width: `${percentage || 100}%` }}
              aria-valuenow={`${percentage || 100}`}
              aria-valuemin="0"
              aria-valuemax={max}
            />
          </div>
          <div className="d-flex justify-content-between mt-2">
            <span className={`text-${color}`}>{branches?.length}</span>
            <span className={`text-primary`}>{max}</span>
          </div>
          <Table
            labels={labels}
            data={branches?.map((item) => ({
              cells: [
                item?.name,
                item?.location,
                managers?.find((m) => m._id == item.managerID)?.name,
              ],
              data: item,
            }))}
            onDelete={removeBranch}
            onEdit={(item) => setData({ ...item, show: true })}
            onAdd={() => setData({ show: true })}
            deleteLoading={deleteLoading}
          />
        </Loader>
      </div>
      <FormModal
        show={data.show == true}
        title={isUpdate ? "Update Site" : "New Site"}
        onSubmit={
          isUpdate
            ? () => changeBranch(company?._id)
            : () => addBranch(company?._id)
        }
        fields={fields}
        onClose={() => setData({ ...data, show: false })}
        loading={addLoading}
      />
    </div>
  );
};

export default Branches;
