import React, { memo } from "react";
import Pagination from "./Pagination";
import EmptyData from "./EmptyData";
import ChecklistQuestionDrag from "./dragAndDrop";
import RiskAssesmentQuestionDrag from "./riskAssesmentDnD";

const Table = ({
  title,
  labels,
  data,
  limit,
  page,
  setPage,
  className,
  options,
  setOption,
  option,
  heightControl,
  editIndex
}) => {
  return (
    <div
      className={`card ${className}`}
      style={{
        zIndex: "0",
        height: heightControl && "300px",
        overflow: heightControl && "auto",
      }}
    >
      {/* Filter */}
      {!options ? null : (
        <div className="filter">
          <a className="icon" href="#" data-bs-toggle="dropdown">
            <i className="bi bi-three-dots"></i>
          </a>
          <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
            <li className="dropdown-header text-start">
              {" "}
              <h6>Filter</h6>{" "}
            </li>
            {options.map((item, index) => (
              <li key={index}>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => setOption(item)}
                >
                  {item}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
      {/* Body */}
      <div className="card-body">
        <h5 className="card-title">
          {title} {options ? <span>| {option}</span> : ""}
        </h5>
        <div className="table-container">
          <table className="table table-hover">
            <EmptyData data={data}>
              <thead>
                <tr>
                  {labels.map((item, index) => (
                    <th key={index} scope="col">
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {title == "Checklist Questions"
                  ? data?.map((item, index) => (
                    <ChecklistQuestionDrag
                      data={item}
                      index={index}
                      question={data}
                    />
                  ))
                  : title == "Risk Assessment Questions" ? data?.map((item, index) => {
                    if (item.noDrag) {
                      return <tr key={index}>
                        {item.fields.map((field, fdx) => {
                          return <td key={fdx}>{field}</td>;
                        })}
                      </tr>
                    } else {
                      return <RiskAssesmentQuestionDrag
                        data={item}
                        index={index}
                        question={data}
                        editIndex={editIndex}
                        lastIndexData={data[data.length - 1]}
                      />
                    }
                  }) : data?.map((item, index) => (
                    <tr key={index}>
                      {item.fields.map((field, fdx) => {
                        return <td key={fdx}>{field}</td>;
                      })}
                    </tr>
                  ))}
              </tbody>
            </EmptyData>
          </table>
        </div>
        {page == undefined || data?.length == 0 ? null : (
          <Pagination page={page} data={data} limit={limit} setPage={setPage} />
        )}
      </div>
    </div>
  );
};

export default memo(Table);
