import { useState } from "react";
import { useDispatch, useSelector } from "react-redux"

import api from "../app/api";
import useAlert from "./useAlert";
import Alert, { DeleteAlert, UpdateAlert } from '../components/Swal Alert/index';

import { setBroadcast, addBroadcast, updateBroadcast, deleteBroadcast } from '../features/broadcast/slice';
import useAuth from "./useAuth";

export default () => {
  const dispatch = useDispatch();
  const { broadcasts } = useSelector(state => state.broadcast)
  const {isManager} = useAuth()
  const { showAlert } = useAlert()

  // STATES
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  // New Broadcast State
  const [data, setData] = useState({
    title: '',
    message: '',
    all: false, // all companies
    companies: [],
  });
  const fetchBroadcasts = async (params) => {
    setLoading(true);
    //(params)
    try {
      const response = await api.get(`/broadcast`, { params });
      //(response.data)
      dispatch(setBroadcast(response.data.items))

      if (params.id) setData(response.data.items[0])
      setLoading(false);
    } catch (err) {
      const message = err.response ? err.response.data?.message ? err.response.data?.message : err.response.data : err;
      //(message)
      showAlert(message, 'danger');
      setLoading(false);
    }
  }
  const getForCompany = async () => {
    const response = !isManager ? await api.get(`/broadcast/getForCompany?id=${localStorage.getItem('_id')}`) : 
    await api.get(`/broadcast/getForCompany?id=${localStorage.getItem('companyID')}`);
    dispatch(setBroadcast(response.data))
  }
  const newBroadcast = async () => {
    setAddLoading(true);
    try {
      const response = await api.post('/broadcast/new', data);
      dispatch(addBroadcast(response.data.data))
      setAddLoading(false);
      setData({ title: '', message: '', all: false, companies: [], })
      showAlert('New broadcast sent successfully', 'success');
    } catch (err) {
      const message = err.response ? err.response.data?.message ? err.response.data?.message : err.response.data : err;
      //(message)
      Alert('error', message);
      // showAlert(message, 'danger');
      setAddLoading(false);
    }
  }
  const changeBroadcast = async () => {
    if (!await UpdateAlert()) return
    setAddLoading(true);
    try {
      const response = await api.put(`/broadcast/update/${data._id}`, data);
      dispatch(updateBroadcast(data))
      setData({ title: '', message: '', all: false, companies: [], })
      setAddLoading(false);
      showAlert('Broadcast updated successfully', 'success');
    } catch (err) {
      const message = err.response ? err.response.data?.message ? err.response.data?.message : err.response.data : err;
      //(message)
      showAlert(message, 'danger');
      setAddLoading(false);
    }
  }
  const removeBroadcast = async (item) => {
    if (!await DeleteAlert()) return
    setDeleteLoading(item);
    try {
      const response = await api.delete(`/broadcast/delete/${item ? item._id : data._id}`);
      dispatch(deleteBroadcast(item))
      setDeleteLoading(false);
      showAlert('Broadcast removed successfully', 'success');
    } catch (err) {
      const message = err.response ? err.response.data?.message ? err.response.data?.message : err.response.data : err;
      //(message)
      showAlert(message, 'danger');
      setDeleteLoading(false);
    }
  }

  return {
    page,
    limit,
    broadcasts,
    loading,
    addLoading,
    deleteLoading,
    data,
    setData,
    setPage,
    fetchBroadcasts,
    newBroadcast,
    changeBroadcast,
    removeBroadcast,
    getForCompany
  }
}