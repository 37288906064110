import { Box, Card, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Screen from "../../components/Screen";
import SubscriptionBullet from "../../components/subscriptionBullet";
import useAuth from "../../hooks/useAuth";
import useSubscription from "../../hooks/useSubscription";
import DoneIcon from '@mui/icons-material/Done';
import { useStripe } from "@stripe/react-stripe-js";
import PaymentModel from "../../components/paymentModel";
import CloseIcon from '@mui/icons-material/Close';
// import "./index.css";
export default function Default() {
  const { subscriptions } = useSelector((state) => state.subscription);
  const stripe = useStripe();
  const { user } = useAuth();
  const {
    getData,
    stripeLoading,
    filter,
    setfilter,
    loading,
    makePayment,
    error,
    setError,
    showPaymentModel,
    setShowPaymentModel,
    setSelectedSubscription,
    updateSubscription,
    handleSubmit,
  } = useSubscription();
  useEffect(() => {
    getData(true);
    setError(null);
  }, []);

  return (
    <Screen
      loading={loading}
      filter={filter}
      setFilter={setfilter}
      flow={[
        { label: "Subscriptions", to: "/subscription" },
        { label: "Home", to: "/" },
        { label: "Subscriptions", to: "/subscription" },
      ]}
    >
      <PaymentModel
        show={showPaymentModel}
        setShow={() => setShowPaymentModel(false)}
        header={"Card Payment"}
        // onSubmit={makePayment}
        onSubmit={handleSubmit}
        error={error}
        stripe={stripe}
        stripeLoading={stripeLoading}
        user={user}
      />

      <Grid container spacing={2}>
        {subscriptions?.map((item, index) => (
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Card
              sx={{
                borderRadius: "10px",
                border:
                  user.subscriptionID?._id == item._id && "3px solid #0d6efd",
              }}
              className="aos-init aos-animate"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <Box sx={{ padding: "35px 30px 20px 5px" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h4 className="title" style={{ color: "#0d6efd" }}>
                    {item.name}
                  </h4>
                </Box>
                <ul>
                  {Object.keys(item).map((item1) => {
                    if (
                      item1 != "_id" &&
                      item1 != "name" &&
                      item1 != "cost" &&
                      item1 != "updatedAt" &&
                      item1 != "createdAt" &&
                      item1 != "priceID" &&
                      item1 != "__v"
                    ) {
                      return (
                        <SubscriptionBullet
                          itemKey={item1}
                          value={item[item1]}
                        />
                      );
                    }
                  })}
                  <SubscriptionBullet
                    itemKey={"Chat"}
                    value={item.allowedInbox ? <DoneIcon sx={{color:'green'}} /> : <CloseIcon sx={{color:'red'}} />}
                  />
                  <SubscriptionBullet
                    itemKey={"Management System"}
                    value={item.allowedManagementSystem ? <DoneIcon sx={{color:'green'}} /> : <CloseIcon sx={{color:'red'}} />}
                  />
                  <SubscriptionBullet
                    itemKey={"Checklist Templates"}
                    value={item.allowedCLTemplates ? <DoneIcon sx={{color:'green'}} /> : <CloseIcon sx={{color:'red'}} />}
                  />
                  <SubscriptionBullet
                    itemKey={"Policy Templates"}
                    value={item.allowedPOTemplates ? <DoneIcon sx={{color:'green'}} /> : <CloseIcon sx={{color:'red'}} />}
                  />
                  <SubscriptionBullet
                    itemKey={"RA Templates"}
                    value={item.allowedRATemplates ? <DoneIcon sx={{color:'green'}} /> : <CloseIcon sx={{color:'red'}} />}
                  />
                </ul>
              </Box>
              <Box sx={{ padding: "10px 20px 35px 20px" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#0d6efd",
                    fontWeight: "bold",
                  }}
                >
                  Cost: £{item.cost} / Month
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    className={`btn btn-sm rounded-5 btn${
                      user.subscriptionID?._id == item._id ? "-outline" : ""
                    }-primary px-5`}
                    disabled={user.subscriptionID?._id == item._id}
                    onClick={() => {
                      // if(item.cost > 0){
                      setShowPaymentModel(true);
                      setSelectedSubscription(item);
                      // }else{
                      //   updateSubscription(user._id, item._id)
                      // }
                    }}
                    // onClick={() => updateSubscription(user._id, item._id)}
                  >
                    {user.subscriptionID?._id == item._id
                      ? "Current Plan"
                      : "Change Plan"}
                  </button>
                </Box>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Screen>
  );
}
