import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

import api from "../app/api";
import useAlert from "./useAlert";

import {
  setTask,
  addTask,
  updateTask,
  deleteTask,
} from "../features/tasks/slice";
import { getFormData } from "../config/functions";
import { DeleteAlert } from "../components/Swal Alert";

export default () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { tasks } = useSelector((state) => state.task);
  //   const { user } = useSelector((state) => state.auth);

  const { showAlert } = useAlert();

  // STATES
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [questions, setQuestions] = useState([]);
  const [data, setData] = useState();
  const updateParams = (params) => {
    return {
      ...params,
      page: 1,
      limit: 200,
    };
  };
  const fetchTasks = async (params) => {
    setLoading(true);
    try {
      const response = await api.get(`/task`, { params });
      dispatch(setTask(response.data));
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const fetchTaskstoDownload = async (params) => {
    setLoading(true);
    try {
      const response = await api.get(`/task`, { params: updateParams(params) });
      return response.data;
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  const fetchTaskById = async (id, params) => {
    setLoading(true);
    try {
      const response = await api.get(`/task/${id}`, { params });
      //(response.data)
      setData(response.data);
      setQuestions(
        response.data?.questions?.map((item) => ({
          _id: item._id,
          hintText: item.hintText,
          answer: item.answer,
          question: item.question,
          required: item.required,
          type: item.type,
          options: item.options,
        }))
      );
      setLoading(false);
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      //(message)
      showAlert(message, "danger");
      setLoading(false);
    }
  };
  const onDelete = async (id) => {
    if (!(await DeleteAlert())) return;
    setDeleteLoading(true);
    try {
      const response = await api.delete(`/task/delete/${id}`);
      dispatch(deleteTask(response.data));
      setDeleteLoading(false);
      navigate(-1);
      showAlert("Task Deleted", "success");
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      //(message)
      showAlert(message, "danger");
      setDeleteLoading(false);
    }
  };
  const createTask = async (checklist) => {
    setAddLoading(checklist?.userID);
    try {
      const response = await api.post(`/task/new`, checklist);
      dispatch(addTask(response.data));
      setAddLoading(false);
      showAlert("Task assigned successfully", "success");
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      //(message)
      showAlert(message, "danger");
      setAddLoading(false);
    }
  };
  const performTask = async (fresh, isRiskAssesment) => {
    const error = questions
      ?.filter(
        (item) =>
          (item.required && item.answer == undefined) || item.answer == ""
      )
      .map((item) => `${item.question} is required`);
    if (error?.length != 0) {
      return showAlert(error.join("\n"), "danger");
    }
    setLoading(true);
    const taskID = location?.state?._id;
    try {
      // console.log(questions)
      var formData = new FormData();
      questions.forEach((item, index) => {
        const fieldname = `questions[${index}]`;
        formData.append(fieldname + "[_id]", item._id);
        formData.append(
          fieldname + "[answer]",
          item.answer ? item.answer : "Not Answered"
        );
      });
      const response = await api.put(`/task/perform/${taskID}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        params: { fresh, isRiskAssesment },
      });
      dispatch(updateTask(response.data));
      setLoading(false);
      showAlert(
        `${
          location?.state?.status == "Approved"
            ? "Submission updated"
            : !isRiskAssesment ? "Task submitted" : "Risk Assessment submitted"
        } Successfully`,
        "success"
      );
      navigate(-1);
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      setLoading(false);
      //(message)
      showAlert(message, "danger");
      setLoading(false);
    }
  };
  const getAnswer = (id) => {
    const question = questions?.find((item) => item._id == id);
    return question.answer;
  };
  const setAnswer = (id, answer, checkbox) => {
    if (!checkbox) {
      const updated = questions?.map((item) =>
        item._id == id ? { ...item, answer } : item
      );
      setQuestions(updated);
    } else {
      const question = questions.filter((item) => id == item._id);
      if (question[0].answer?.includes(answer)) {
        const updatedAnswer = question[0].answer?.filter(
          (item) => item != answer
        );
        const updated = questions?.map((item) =>
          item._id == id ? { ...item, answer: updatedAnswer } : item
        );
        setQuestions(updated);
      } else {
        const updated = questions?.map((item) =>
          item._id == id
            ? {
                ...item,
                answer: item.answer ? [...item.answer, answer] : [answer],
              }
            : item
        );
        setQuestions(updated);
      }
    }
  };

  // Records
  const downloadExcel = (record) => {
    const type =
      "application/vnd.openxmlformats-officedocuments.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const send = record.questions.map((item) => ({
      Question: item.question,
      Answer: item.answer,
    }));

    const ws = XLSX.utils.json_to_sheet(send);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const fileData = new Blob([excelBuffer], { type });
    FileSaver.saveAs(fileData, record.title + fileExtension);
  };

  return {
    tasks,
    data,
    page,
    limit,
    loading,
    setLoading,
    addLoading,
    deleteLoading,
    questions,
    setQuestions,
    fetchTasks,
    fetchTaskById,
    setPage,
    performTask,
    createTask,
    getAnswer,
    setAnswer,
    downloadExcel,
    onDelete,
    fetchTaskstoDownload,
  };
};
