import React from "react";
import { useSelector } from "react-redux";
import SubmitButton from "../../components/SubmitButton";
import Table from "../../components/Table";
import useAuth from "../../hooks/useAuth";
const FilesList = ({ handleAddEmail, selectedEmails }) => {
  const { isAdmin, isCompany } = useAuth();
  const { email } = useSelector((state) => state.Email);

  const labels = ["Email", "Name", (isAdmin || isCompany) && "Actions"].filter(
    (item) => item
  );
  
  
  const data = email
    ? email.map((item) => {
        return {
          data: item,
          fields: [
            item?.email,
            item?.name,
            (isAdmin || isCompany) && (
              <SubmitButton
                style={{ marginLeft: "15px" }}
                title={"Select"}
                onClick={() => handleAddEmail(item.email)}
                color={"success"}
                loaderColor={"white"}
                disabled={selectedEmails.includes(item.email)}
              />
            ),
          ].filter((it) => it),
        };
      })
    : [];
  return (
    <div className="col-md-12 col-lg-6">
        <Table labels={labels} data={data} heightControl={true} />
    </div>
  );
};

export default FilesList;
