import React, { useEffect, useRef, useState } from "react";

import useNature from "../../hooks/useNature";

import SubmitButton from "../../components/SubmitButton";
import imagePlaceholder from "../../assets/placeholder-img.png";
import { keys } from "../../config/keys";
import { useSelector } from "react-redux";
import FormField1 from "../../components/FormField";
import SignatureCanvas from "react-signature-canvas";
import { b64toBlob, capitalFirst } from "../../config/functions";
import styles from "../../components/styles.module.css";
const FormField = ({ field }) => {
  const onChange = (e) => field.setValue(e.target.value);

  return (
    <div className="row mb-3">
      <label htmlFor={field.id} className="col-md-4 col-lg-3 col-form-label">
        {field.label}
        {field.required ? <span className="text-danger"> *</span> : null}
      </label>
      <div className="col-md-8 col-lg-9">
        {field.type == "longtext" ? (
          <textarea
            name={field.id}
            className="form-control"
            id={field.id}
            style={{ height: 100 }}
            onChange={onChange}
            value={field.value}
          />
        ) : field.type == "dropdown" ? (
          <>
            <select
              id={field.id}
              value={field.value}
              className="form-select"
              onChange={onChange}
            >
              {field?.options?.map((val, index) => {
                const value = !!val.name ? val._id : val;
                const display = !!val.name ? val.name : val;
                return (
                  <option
                    key={index}
                    selected={field?.value == value}
                    value={value}
                  >
                    {display}
                  </option>
                );
              })}
            </select>
          </>
        ) : (
          <input
            name={field.id}
            type={field.type}
            className="form-control"
            id={field.id}
            value={field.value}
            onChange={onChange}
          />
        )}
      </div>
    </div>
  );
};

const Edit = ({ data, setData, onSubmit, loading, isManager, branchID, isAdmin }) => {
  const { natures, fetchNatures } = useNature();
  const { subscriptions } = useSelector((state) => state.subscription);
  const [signatureHandler, setsignatureHandler] = useState("");
  const canvasRef = useRef();
  const fields =
    isManager || branchID
      ? [
        {
          label: "Name",
          value: data?.name,
          setValue: (value) => setData({ ...data, name: value }),
          type: "text",
          id: "name",
        },
        {
          label: "Location",
          value: data?.location,
          setValue: (value) => setData({ ...data, location: value }),
          type: "text",
          id: "location",
        },
        {
          label: "About",
          value: data?.about,
          setValue: (value) => setData({ ...data, about: value }),
          type: "longtext",
          id: "about",
        },
      ]
      : [
        ...(isAdmin && subscriptions
          ? [
            {
              label: "Subscription",
              value: data?.subscriptionID,
              setValue: (value) =>
                setData({ ...data, subscriptionID: value }),
              type: "dropdown",
              id: "subscriptionID",
              options: subscriptions,
              required: true,
            },
          ]
          : []),
        {
          label: "Email Address",
          value: data?.email,
          setValue: (value) => setData({ ...data, email: value }),
          type: "email",
          id: "emailaddress",
          required: true,
        },
        {
          label: "About",
          value: data.about,
          setValue: (value) => setData({ ...data, about: value }),
          type: "longtext",
          id: "about",
        },
        {
          label: "Business Name",
          value: data?.name,
          setValue: (value) => setData({ ...data, name: value }),
          type: "text",
          id: "companyName",
          required: true,
        },
        {
          label: "Nature of Business",
          value: data.nature?._id,
          setValue: (value) => setData({ ...data, nature: value }),
          type: "dropdown",
          id: "businessnature",
          options: natures,
          required: true,
        },
        // {
        //   label: "Postion",
        //   value: data.position,
        //   setValue: (value) => setData({ ...data, position: value }),
        //   type: "text",
        //   id: "postion",
        //   required: false,
        // },
        // {
        //   label: "Manager Name",
        //   value: data.adminName,
        //   setValue: (value) => setData({ ...data, adminName: value }),
        //   type: "text",
        //   id: "postion",
        //   required: false,
        // },
        // {
        //   label: "Manager Email",
        //   value: data.adminEmail,
        //   setValue: (value) => setData({ ...data, adminEmail: value }),
        //   type: "text",
        //   id: "postion",
        //   required: false,
        // },
        {
          label: "N.o of Employees",
          value: data.employees,
          setValue: (value) => setData({ ...data, employees: value }),
          type: "number",
          id: "postion",
        },
        {
          label: "Phone Number",
          value: data.telephone1,
          setValue: (value) => setData({ ...data, telephone1: value }),
          type: "phone",
          id: "postion",
          required: true,
        },
        {
          label: "Telephone 2",
          value: data.telephone2,
          setValue: (value) => setData({ ...data, telephone2: value }),
          type: "phone",
          id: "postion",
        },
        {
          label: "Address",
          value: data.address,
          setValue: (value) => setData({ ...data, address: value }),
          type: "text",
          id: "postion",
          required: false,
        },
        {
          label: "Website",
          value: data.website,
          setValue: (value) => setData({ ...data, website: value }),
          type: "text",
          id: "postion",
        },
      ];

  const onClick = (e) => {
    e.preventDefault();
    const error = fields
      ?.filter((item) => item.required && !item.value)
      ?.map((item) => `${item.label} can not be empty`);
    onSubmit(error.join("\n"));
  };

  const src = data?.image
    ? URL.createObjectURL(data?.image)
    : data?.imageURL
      ? `${keys.BASE_LOCAL_URL}/${data?.imageURL}`
      : imagePlaceholder;
  useEffect(() => {
    // This code runs after the ref is bound to the element
    console.log("canvasRef")
    console.log(canvasRef)
    // if (canvasRef.current) {
    //   console.log('Ref is bound:', canvasRef.current);
    //   canvasRef.current.focus(); // Example DOM manipulation
    // }
  }, []);
  useEffect(() => {
    fetchNatures({ hide: ["Default"] });
  }, []);

  return (
    <div className="tab-pane fade profile-edit pt-3" id="profile-edit">
      <form>
        {isManager || branchID ? null : (
          <div className="row mb-3">
            <label
              htmlFor="profileImage"
              className="col-md-4 col-lg-3 col-form-label"
            >
              Company Logo
            </label>
            <div className="col-md-8 col-lg-9">
              <img
                src={src}
                alt="Profile"
                style={{ height: 100, width: 100 }}
              />
              <div className="pt-2">
                <input
                  type="file"
                  accept="image/*"
                  id="pfp"
                  className="hidden"
                  onChange={(e) =>
                    setData({ ...data, image: e.target.files[0] })
                  }
                />
                <label
                  htmlFor="pfp"
                  className="btn btn-primary btn-sm me-1 text-white"
                  title="Upload new profile image"
                >
                  <i className="bi bi-upload"></i>
                </label>
                <button
                  className="btn btn-danger btn-sm"
                  title="Remove my profile image"
                  onClick={(e) => {
                    e.preventDefault();
                    setData({ ...data, image: undefined, imageURL: undefined });
                  }}
                >
                  <i className="bi bi-trash"></i>
                </button>
              </div>
            </div>
          </div>
        )}
        {fields?.map((item, index) => (
          <FormField field={item} key={index} />
        ))}
        
        <div className="row mb-3">
          <div div className="border border-secondary mb-2">
            <SignatureCanvas
              ref={canvasRef}
              penColor="green"
              canvasProps={{  className: 'signature-canvas' }}
              onEnd={(e) => {
                const canvas = canvasRef.current
                const b64 = canvas?.toDataURL("image/png");
                const convertedB64 = b64toBlob(b64);
                setsignatureHandler(convertedB64);
              }}
            />
          </div>
          <button
            onClick={() => {
              const canvas = canvasRef.current;
              canvas?.clear();
              setsignatureHandler("");
            }}
            className="btn btn-outline-primary"
          >
            Clear
          </button>
        </div>
        
        {/* <FormField1
          item={{
            value: [signatureHandler],
            setValue: (value, checkbox) => {
              setsignatureHandler(value);
            },
            type: "signature",
          }}
          disabled={false} /> */}
        <div className="text-center">
          <SubmitButton
            title={"Save Changes"}
            color={"primary"}
            onClick={onClick}
            loading={loading}
            loaderColor={"white"}
          />
        </div>
      </form>
    </div>
  );
};

export default Edit;
