import React, { useEffect } from 'react'
import Loader from '../../components/Loader';

const ItemList = ({ all, selected, setSelected, data, title, disabled, allDisabled, setAll, loading, setMore }) => {
    useEffect(() => {
        if (all && all == true) {
            setSelected([]);
            if (setMore) setMore(undefined)
        }
    }, [])

    useEffect(() => {
        if (selected?.length > 0) {
            setAll(false);
        }
    }, [selected])

    return (
        <div style={{ flex: 1 }}>
            <h5>{title}</h5>
            <div className="form-check" >
                <input className="form-check-input" type="checkbox" checked={all} id="all" onChange={allDisabled || disabled ? null : (e) => setAll(!all)} allDisabled={allDisabled || disabled} />
                <label className="form-check-label" for="all">
                    All
                </label>
            </div>
            <Loader loading={loading}>
                {
                    data?.map((item, index) => {
                        const id = `item${index}`;
                        const checked = !selected ? false : selected?.find(select => select?._id == item?._id);
                        return (
                            <div className="form-check d-flex gap-2" key={index}>
                                <input
                                    className="form-check-input"
                                    checked={checked || all}
                                    type="checkbox"
                                    value=""
                                    id={id}
                                    disabled={disabled}
                                    onChange={disabled ? null : (e) => {
                                        const items = checked
                                            ? selected?.filter(select => select?._id != item?._id)
                                            : [...(selected ? selected : []), item];
                                        setSelected(items)
                                        if (setMore) setMore(!checked ? item : undefined)
                                    }}
                                />
                                <label className="form-check-label" for={id}>
                                    {item.name}
                                </label>


                                {!setMore || !checked ? null :
                                    <span
                                        className='text-primary'
                                        onClick={() => setMore(item)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <i className="bi bi-chevron-double-right"></i>
                                    </span>
                                }
                            </div>
                        )
                    })
                }
                {
                    data?.length == 0 || !data ? <span className='text-secondary'>No items</span> : null
                }
            </Loader>
        </div>
    )
}

export default ItemList