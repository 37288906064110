import {
  Text,
  Font,
  Document,
  Page,
  StyleSheet,
  View,
  Image,
} from "@react-pdf/renderer";
import imagePlaceholder from "../../assets/logo.png";
import { keys } from "../../config/keys";
import parse from "html-react-parser";
import { Fragment, useEffect, useState } from "react";
// Font.register({
//   family: "Nunito",
//   fonts:[
//     {src:require('../../assets/fonts/NunitoSans-Bold.ttf'), fontWeight:'bold'},
//     {src:require('../../assets/fonts/NunitoSans-Regular.ttf'), fontWeight:'normal'}
//   ]
//   // src: require("../../assets/fonts/NunitoSans-Regular.ttf"),
// });
Font.register({
  family: "Nunito",
  fonts: [
    {
      src: require("../../assets/fonts/NunitoSans-Regular.ttf"),
      fontWeight: "normal",
    },
    {
      src: require("../../assets/fonts/NunitoSans-Bold.ttf"),
      fontWeight: "bold",
    },
    {
      src: require("../../assets/fonts/NunitoSans-Light.ttf"),
      fontWeight: "light",
    },
    {
      src: require("../../assets/fonts/NunitoSans-SemiBold.ttf"),
      fontWeight: "600",
    },
    // Add more font weights as needed
  ],
});
const styles = StyleSheet.create({
  image: {
    width: 20,
    height: 20,
    marginBottom: 10,
  },
  line: {
    width: 110, // Width of the line
    height: 1, // Height of the line (can be adjusted to make it thicker)
    backgroundColor: "black", // Color of the line
    marginVertical: 3, // Vertical margin (optional)
    marginHorizontal: 31,
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    backgroundColor: "white",
    fontFamily:'Nunito',
  },
  title: {
    fontSize: 28,
    textAlign: "center",
    fontFamily: "Nunito",
    color: "#012970",
  },
  signature: {
    fontSize: 22,
    paddingHorizontal: 35,
    fontFamily: "Nunito",
    color: "#012970",
  },
  card: {
    backgroundColor: "white",
    padding: 20,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
    fontFamily: "Nunito",
    fontWeight:'light'
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  pageDate: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "right",
    color: "grey",
    marginRight: 35,
  },
  // for description
  p: {
    fontSize: 11,
    padding: "0 0 15 1",
    textAlign: "justify",
    fontFamily: "Nunito",
    fontWeight:'normal'
  },
  h1: {
    fontSize: 16,
    margin: 0,
    padding: 0,
    fontFamily: "Nunito",
    color: "#5B9BD5",
    fontWeight:'bold'
  },
  h2: {
    fontSize: 14,
    margin: 0,
    padding: 0,
    fontFamily: "Nunito",
    color: "#5B9BD5",
    fontWeight:'bold'
  },
  strong: {
    fontSize: 11,
    padding: "0 0 15 1",
    textAlign: "justify",
    fontFamily: "Nunito",
    display: "inline",
    fontWeight:'bold'
  },
});

const checkForExsistance = (result, tag, value) => {
  return result.some((obj) => obj.tag === tag && obj.value === value);
};
const convertHtmlToPdfFormat = (htmlString) => {
  // Ensure htmlString is a string or an array of strings and flatten it
  if (Array.isArray(htmlString)) {
    htmlString = htmlString.join("");
  }

  if (typeof htmlString !== "string") {
    console.error("Expected a string but got:", typeof htmlString, htmlString);
    return null;
  }
  let result = [];
  parse(htmlString, {
    replace: ({ name, children, ...attribs }) => {
      switch (name) {
        case "p":
          if (children.length == 1) {
            if (
              !result.includes({
                tag: "p",
                value: children[0]?.data,
              })
            ) {
              result.push({
                tag: "p",
                value: children[0]?.data,
              });
            }
          } else {
            children?.map((item) => {
              if (item.name) {
                // return <Text style={styles.p}>{item?.children[0]?.data}</Text>;
                result.push({
                  tag: item.name,
                  value: item?.children[0]?.data,
                });
              } else {
                result.push({
                  tag: "p",
                  value: item?.data,
                });
              }
            });
          }
          break;
        case "h1":
          if (children.length == 1) {
            result.push({
              tag: "h1",
              value: children[0]?.data,
            });
          } else {
            children?.map((item) => {
              if (item.name) {
                // return <Text style={styles.p}>{item?.children[0]?.data}</Text>;
                result.push({
                  tag: item.name,
                  value: item?.children[0]?.data,
                });
              } else {
                result.push({
                  tag: "h1",
                  value: item?.data,
                });
              }
            });
          }
          break;
        case "h2":
          if (children.length == 1) {
            result.push({
              tag: "h2",
              value: children[0]?.data,
            });
          } else {
            children?.map((item) => {
              if (item.name) {
                // return <Text style={styles.p}>{item?.children[0]?.data}</Text>;
                result.push({
                  tag: item.name,
                  value: item?.children[0]?.data,
                });
              } else {
                result.push({
                  tag: "h2",
                  value: item?.data,
                });
              }
            });
          }
          break;
        case "strong":
          if (children.length == 1) {
            if (!checkForExsistance(result, "strong", children[0]?.data)) {
              result.push({
                tag: "strong",
                value: children[0]?.data,
              });
            }
          } else {
            children?.map((item) => {
              if (item.name) {
                if (
                  !checkForExsistance(
                    result,
                    item.name,
                    item?.children[0]?.data
                  )
                ) {
                  result.push({
                    tag: item.name,
                    value: item?.children[0]?.data,
                  });
                }
              } else {
                if (!checkForExsistance(result, "strong", item?.data)) {
                  result.push({
                    tag: "strong",
                    value: item?.data,
                  });
                }
              }
            });
          }
          break;
      }
    },
  });
  return result;
};

const PDF = ({ data }) => {
  const image = data?.companyID?.imageURL
    ? `${keys.BASE_LOCAL_URL}/${data?.companyID?.imageURL}`
    : imagePlaceholder;

  const [outputArray, setOutputArray] = useState([]);
  // Function to preprocess the replacedDescription array
  const preprocessArray = () => {
    const result = [];
    let replacedDescription = convertHtmlToPdfFormat(data?.description || "");
    let currentLine = []; // Array to hold consecutive p and strong tags

    const flushCurrentLine = () => {
      if (currentLine.length > 0) {
        result.push(
          <Text style={styles.p}>
            {currentLine.map((item, index) => (
              <Text
                key={index}
                style={item.tag === "strong" ? styles.strong : styles.p}
              >
                {item.value}
                {item.tag === "strong" ||
                (item.tag == "p" && currentLine[index + 1]?.tag == "strong")
                  ? " "
                  : "\n"}
              </Text>
            ))}
          </Text>
        );
        currentLine = [];
      }
    };

    replacedDescription.forEach((item, index) => {
      if (item.tag === "p" || item.tag === "strong") {
        currentLine.push(item);
      } else {
        flushCurrentLine();
        switch (item.tag) {
          case "h1":
            result.push(<Text style={styles.h1}>{item.value}</Text>);
            break;
          case "h2":
            result.push(<Text style={styles.h2}>{item.value}</Text>);
            break;
          // Add more cases as needed
          default:
            result.push(<Text>{item.value}</Text>);
        }
      }
    });

    // Flush any remaining items in the current line
    flushCurrentLine();

    return result;
  };

  useEffect(() => {
    const processedArray = preprocessArray();
    setOutputArray(processedArray);
  }, []);
  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.header} fixed>
          CompCheck
        </Text>
        <Text style={styles.header} fixed>
          {data.companyID?.name}
        </Text>
        <View
          style={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            src={image}
            style={{ width: 50, height: 50, marginBottom: 10 }}
          />
          <Text style={styles.title}>{data?.title}</Text>
        </View>
        {/* <Text style={styles.author}>{data?.categoryID?.name}</Text> */}
        <View style={styles.card}>
          {outputArray.map((item, index) => (
            <Fragment key={index}>{item}</Fragment>
          ))}
        </View>
        {data.signature?.includes("uploads") ? (
          <Image
            src={`${keys.BASE_LOCAL_URL}/${data.signature}`}
            style={{
              width: 100,
              height: 50,
              marginBottom: 10,
              marginHorizontal: 35,
            }}
          />
        ) : (
          <Text style={styles.signature}>{data?.signature}</Text>
        )}
        <View style={styles.line} />
        <Text
          style={{
            fontSize: 13,
            paddingHorizontal: 54,
            fontFamily: "Nunito",
            color: "#012970",
          }}
        >
          Signature
        </Text>
        <View
          style={{
            display: "flex",
            flexDirection: "row", // Arrange children in a row
            alignItems: "center",
            paddingHorizontal:20,
            paddingVertical:20
          }}
        >
          <Text style={styles.strong} render={() => "Last Reviewed: "} />
          <Text
            style={styles.p}
            render={() =>
              new Date(data?.lastReviewed).toLocaleDateString("en-GB")
            }
          />
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
        <Text
          style={styles.pageDate}
          render={() => new Date(data?.createdAt).toLocaleDateString("en-GB")}
          fixed
        />
      </Page>
    </Document>
  );
};

export default PDF;
