import { Configuration, OpenAIApi } from "openai";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../app/api";
import { DeleteAlert } from "../components/Swal Alert/index";
import { setPolicy } from "../features/policies/slice";
import useAlert from "./useAlert";
import useAuth from "./useAuth";
const configuration = new Configuration({
  apiKey: "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",
});

const openai = new OpenAIApi(configuration);
// eslint-disable-next-line
export default () => {
  const dispatch = useDispatch();
  const { isCompany, isAdmin } = useAuth();
  const [loading, setLoading] = useState(false);
  const [aiLoading, setAiLoading] = useState(false);
  const [signatureType, setSignatureType] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState({
    title: "",
    description: "",
    categoryID: "",
    signature: "",
    companyID: "",
    aiResponse: `Disclaimer: Only use AI for inspiration. For accurate & up-to-date advice, `,
  });
  const [filter, setFilter] = useState({
    company: "",
    category: "",
  });
  const [aiRequest, setAiRequest] = useState("");
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const autoFill = async (text, company) => {
    text = text.replace(/\[\[CompanyEmail\]\]/g, company.email);
    text = text.replace(/\[\[CompanyName\]\]/g, company.name);
    text = text.replace(/\[\[CompanyRole\]\]/g, company.role);
    text = text.replace(/\[\[CompanyNature\]\]/g, company.nature?.name);
    text = text.replace(/\[\[CompanyNoOfEmployees\]\]/g, company.employees);
    text = text.replace(/\[\[CompanyWebsite\]\]/g, company.website);
    text = text.replace(/\[\[CompanyTelephone1\]\]/g, company.telephone1);
    text = text.replace(/\[\[CompanyTelephone2\]\]/g, company.telephone2);
    text = text.replace(/\[\[CompanyNoOfBranches\]\]/g, company.branches);
    text = text.replace(/\[\[CompanyAddress\]\]/g, company.address);
    text = text.replace(/\[\[CompanyWorkStartTime\]\]/g, company.workStartTime);
    text = text.replace(/\[\[CompanyWorkEndTime\]\]/g, company.workEndTime);
    return text;
  };

  const newPolicy = async () => {
    setLoading(true);
    try {
      let newDescription = ''
      // eslint-disable-next-line
      if (isAdmin && data.companyID && data.companyID != "") {
        const companyData = await api.get(`/company/${data.companyID}`);
        newDescription = await autoFill(data.description,companyData.data)
        // setData({...data,description:newDescription})
      } else if(isCompany) {
        const companyData = await api.get(`/company/${localStorage.getItem('_id')}`);
        newDescription = await autoFill(data.description,companyData.data)
        // setData({...data,description:newDescription})
      }else{
        newDescription = data.description
      }
      const response = await api.post("/policies/add", {
        ...data,
        createdBy: localStorage.getItem("_id"),
        companyID: isCompany ? localStorage.getItem("_id") : data.companyID,
        description:newDescription,
      });
      if (response.data?.status) {
        getPolicies();
        setLoading(false);
        setData({
          title: "",
          description: "",
          category: "",
          signature: "",
          aiResponse: `Disclaimer: Only use AI for inspiration. For accurate & up-to-date advice, contact us here...`,
        });
        showAlert("New Policy added ", "success");
        navigate("/policy");
      } else {
        showAlert(response.data?.message, "danger");
      }
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      showAlert(message, "danger");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const updatePolicy = async (id) => {
    setLoading(true);
    try {
      let newDescription = '';
      // eslint-disable-next-line
      if (isAdmin && data.companyID && data.companyID != "") {
        const companyData = await api.get(`/company/${data.companyID}`);
        newDescription = await autoFill(data.description,companyData.data)
        // setData({...data,description:newDescription})
      } else if(isCompany) {
        const companyData = await api.get(`/company/${localStorage.getItem('_id')}`);
        newDescription = await autoFill(data.description,companyData.data)
        // setData({...data,description:newDescription})
      }else{
        newDescription = data.description
      }
      const response = await api.put("/policies/update", {
        ...data,
        createdBy: localStorage.getItem("_id"),
        description:newDescription,
        _id: id,
      });
      if (response.data?.status) {
        getPolicies();
        setLoading(false);
        setData({
          title: "",
          description: "",
          category: "",
          signature: "",
          aiResponse: `Disclaimer: Only use AI for inspiration. For accurate & up-to-date advice, contact us here...`,
        });
        showAlert("Policy Updated", "success");
        navigate("/policy");
      } else {
        showAlert(response.data?.message, "danger");
      }
    } catch (err) {
      const message = err.response
        ? err.response.data?.message
          ? err.response.data?.message
          : err.response.data
        : err;
      showAlert(message, "danger");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const getTemplates = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/policies/getTemplates`);
      if (response.data.status) {
        dispatch(setPolicy(response.data.result));
      } else {
        dispatch(setPolicy([]));
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const getPolicies = async (page) => {
    setLoading(true);
    try {
      const response = await api.get(
        `/policies/getPolicies?companyID=${
          isAdmin ? filter.company : isCompany ? localStorage.getItem("_id") :localStorage.getItem("companyID")
        }&categoryID=${filter.category}&page=${page}&limit=${limit}`
      );
      if (response.data.status) {
        dispatch(setPolicy(response.data.result));
      } else {
        dispatch(setPolicy([]));
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const getPolicy = async (id) => {
    setLoading(true);
    try {
      const response = await api.get(`/policies/getPolicy?_id=${id}`);
      if (response.data?.status) {
        setData({
          ...data,
          title: response.data?.result?.title,
          categoryID: response.data?.result?.categoryID?._id,
          companyID: response.data?.result?.companyID?._id,
          description: response.data?.result?.description,
          signature: response.data?.result?.signature,
        });
        if (response.data?.result?.signature?.includes("uploads")) {
          setSignatureType("File");
        } else {
          setSignatureType("Text");
        }
      } else {
        showAlert(response.data?.message, "danger");
      }
    } catch (error) {
      showAlert("error", "danger");
    } finally {
      setLoading(false);
    }
  };
  const deletePolicy = async (id) => {
    if (!(await DeleteAlert())) return;
    setLoading({ _id: id });
    try {
      const response = await api.delete(`/policies/deletePolicy?_id=${id}`);
      if (response.data?.status) {
        showAlert(response.data?.message, "success");
        getPolicies();
      } else {
        showAlert(response.data?.message, "danger");
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const handleEditorChange = (value) => {
    // setEditorState(value);
    setData({ ...data, description: value });
  };

  const handleAssistentRequest = async () => {
    setAiLoading(true);
    setData({ ...data, aiResponse: "" });
    try {
      const messages = [
        {
          role: "user",
          content: `${aiRequest}`,
        },
      ];
      const completion = await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages,
      });
      let newText = completion.data.choices[0].message.content;
      // newText = newText.replace(/\n/g, '<br>');
      setData({ ...data, aiResponse: newText });
    } catch (error) {
      showAlert("danger", "Unexpected error occured");
    } finally {
      setAiLoading(false);
    }
  };
  return {
    getPolicies,
    filter,
    setFilter,
    loading,
    setLoading,
    handleAssistentRequest,
    handleEditorChange,
    aiLoading,
    setAiLoading,
    newPolicy,
    data,
    setData,
    aiRequest,
    setAiRequest,
    getPolicy,
    updatePolicy,
    deletePolicy,
    signatureType,
    setSignatureType,
    getTemplates,
    page, setPage,
    limit,setLimit
  };
};
