export const capitalFirst = (str) => {
    const sections = str.split("/");
    for (let i = 0; i < sections.length; i++) {
        const words = sections[i].split(" ");
        for (let j = 0; j < words.length; j++) {
            if (words[j].toUpperCase() === "NA") {
                words[j] = "NA";
            } else {
                words[j] = words[j].charAt(0).toUpperCase() + words[j].slice(1);
            }
        }
        sections[i] = words.join(" ");
    }
    return sections.join("/");
}

export const getTimeDifferenceString = (date) => {
    const now = Date.now();
    const then = new Date(date);
    const timeDiff = Math.abs(now - then.getTime());
    const diffInMinutes = Math.floor(timeDiff / (1000 * 60));
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInMinutes / (60 * 24));
    const diffInWeeks = Math.floor(diffInMinutes / (60 * 24 * 7));
    const diffInMonths = Math.floor(diffInMinutes / (60 * 24 * 30));
    const diffInYears = Math.floor(diffInMinutes / (60 * 24 * 365));

    if (diffInYears > 0) {
        return `${diffInYears} year${diffInYears > 1 ? 's' : ''}`;
    } else if (diffInMonths > 0) {
        return `${diffInMonths} month${diffInMonths > 1 ? 's' : ''}`;
    } else if (diffInWeeks > 0) {
        return `${diffInWeeks} week${diffInWeeks > 1 ? 's' : ''}`;
    } else if (diffInDays > 0) {
        return `${diffInDays} day${diffInDays > 1 ? 's' : ''}`;
    } else if (diffInHours > 0) {
        return `${diffInHours} hour${diffInHours > 1 ? 's' : ''}`;
    } else {
        return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''}`;
    }
}


export const hhmm = (date) => {
    var time = new Date(date);
    if (isNaN(time)) return ''
    return (
        ("0" + time.getHours()).slice(-2) + ":" +
        ("0" + time.getMinutes()).slice(-2)
    )
}
export const ddmmyyyy = (date) => {
    var time = new Date(date);
    if (isNaN(time)) return ''
    const str = time.toLocaleDateString('ko-KR', { year: 'numeric', day: '2-digit', month: '2-digit' }).replaceAll('. ', '-').slice(0, -1)
    return str
}


export const getFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((value, index) => {
        if (value.includes('image')) {
            for (const image of data[value]) {
                formData.append(value, image);
            }
        }
        else
            formData.append(value, data[value])
    })
    return formData
}

export const getTimePeriod = (period) => {
    const now = new Date();
    const from = new Date();
    const to = new Date();

    switch (period.toLowerCase()) {
        case 'all':
            from.setHours(0, 0, 0, 0);
            to.setHours(23, 59, 59, 999);
            break;

        case 'today':
            from.setHours(0, 0, 0, 0);
            to.setHours(23, 59, 59, 999);
            break;

        case 'yesterday':
            from.setDate(now.getDate() - 1);
            from.setHours(0, 0, 0, 0);
            to.setDate(now.getDate() - 1);
            to.setHours(23, 59, 59, 999);
            break;

        case 'this week':
            const firstDayOfWeek = now.getDate() - now.getDay();
            from.setDate(firstDayOfWeek);
            from.setHours(0, 0, 0, 0);
            const lastDayOfWeek = firstDayOfWeek + 6;
            to.setDate(lastDayOfWeek);
            to.setHours(23, 59, 59, 999);
            break;

        case 'this month':
            from.setDate(1);
            from.setHours(0, 0, 0, 0);
            to.setMonth(now.getMonth() + 1);
            to.setDate(0);
            to.setHours(23, 59, 59, 999);
            break;

        case 'last month':
            from.setMonth(now.getMonth() - 1);
            from.setDate(1);
            from.setHours(0, 0, 0, 0);
            to.setDate(0);
            to.setHours(23, 59, 59, 999);
            break;

        default:
            return null; // Return null for unsupported periods
    }

    return {
        from: from.getTime(),
        to: to.getTime(),
    };
};

export const isPermission = (permissions, route) => (
    permissions?.routes.some(item => item.includes(route))
)

export const formatBytes = (bytes) => {
    if (bytes === 0) {
        return '0 Bytes';
    }

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));

    if (i === 0) {
        return `${bytes} ${sizes[i]}`;
    }

    return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
}

export const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString('en-GB', {
        hour12: true,
        hour: '2-digit',
        minute: '2-digit'
    })
}

export const compareTimes = (time1, time2) => {
    const date1 = new Date(time1);
    const date2 = new Date(time2);

    const dateFixed = new Date(2000, 0, 1); // January 1, 2000 (or any other date you prefer)
    const dateTime1 = new Date(dateFixed);
    dateTime1.setHours(date1.getHours(), date1.getMinutes(), 0, 0);

    const dateTime2 = new Date(dateFixed);
    dateTime2.setHours(date2.getHours(), date2.getMinutes(), 0, 0);

    if (dateTime1 <= dateTime2) {
        return -1;
    } else if (dateTime1 >= dateTime2) {
        return 1;
    } else {
        return 0;
    }
}

export const b64toBlob = (url, sliceSize = 512) => {
    const match = url.match(/^data:(.*?);/);
    console.log(url)
    const b64Data = url.split(',')[1];
    const contentType = match[1];

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}