import React, { useEffect, useState } from 'react'
import Modal from "react-bootstrap/Modal";

import useCompanies from '../hooks/useCompanies';
import useTask from '../hooks/useTask';
import useAuth from '../hooks/useAuth';

import Table from './Table';
import Loader from './Loader';
import FormField from './FormField';
import SubmitButton from './SubmitButton';

const UserModal = ({ show, handleClose, checklist, isRiskAssesment }) => {
    const labels = ['#', 'Name', 'Position', 'Site Name', 'Actions'];
    const { isCompany } = useAuth()
    const {
        managers,
        branches,
        loading,
        fetchEmployees,
    } = useCompanies();
    const { addLoading, createTask } = useTask();

    const [position, setPosition] = useState('Supervisor');

    const field = {
        placeholder: 'Select Position',
        value: position,
        setValue: (value) => setPosition(value),
        type: 'dropdown',
        options: ['Supervisor', 'Manager'],
        className: 'col-4 mb-0'
    }


    const companyID = show?.companyID?.id;
    useEffect(() => {
        if (show) fetchEmployees(companyID, { position })
    }, [show, position])

    const data = managers
        ?.map((item, index) => {
            const checklistID = show?._id;
            const userID = item._id;
            return {
                data: item,
                fields: [
                    index + 1,
                    item.name,
                    item.position,
                    branches?.find(b => b._id == item.branchID)?.name,
                    <SubmitButton
                        title={'Assign'}
                        loading={addLoading == userID}
                        color={'primary'}
                        loaderColor={'white'}
                        onClick={() => createTask({
                            ...checklist,
                            ...(isRiskAssesment ? {riskAssesmentID: checklistID}: {checklistID}),
                            companyID,
                            userID
                        })
                        }
                    />,
                ]
            }
        })

    return (
        <Modal show={show} centered onHide={handleClose}>
            <Modal.Header>
                <div className='row w-100'>
                    <Modal.Title className={isCompany ? 'col-8' : 'col-12'}>Assign Task</Modal.Title>
                    {isCompany ? <FormField item={field} /> : null}
                </div>
            </Modal.Header>
            <Modal.Body>
                <Loader loading={loading} center >
                    <Table labels={labels} data={data} />
                </Loader>
            </Modal.Body>
        </Modal>
    )
}

export default UserModal