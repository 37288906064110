import {
  Font
} from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import './App.css';
import NoPage from "./components/NoPage";
import { protectedRoutes, publicRoutes } from "./config/routes";
import useSocket from "./hooks/useSocket";
const Redirect = ({ element }) => {
  const { token } = useSelector((state) => state.auth);
  return !token ? <Navigate to={"/login"} /> : element;
};
function App() {
  useSocket()
  return (
    <Routes>
      {publicRoutes?.map((route, index) => (
        <Route key={index} {...route} />
      ))}
      {protectedRoutes?.map((route, index) => (
        <Route
          key={index}
          {...route}
          element={<Redirect {...route} />}
        />
      ))}
      <Route
        path="/"
        element={<Redirect element={<Navigate to={"/dashboard"} />} />}
      />
      <Route path="*" element={<NoPage />} />
    </Routes>
  );
}

export default App;
