import {
  Text,
  Font,
  Document,
  Page,
  StyleSheet,
  View,
  Link,
  Image,
} from "@react-pdf/renderer";
import imagePlaceholder from "../../assets/logo.png";
import { keys } from "../../config/keys";
import { Checkbox } from "@mui/material";
import checkmark from "../../assets/checkmark.png";
Font.register({
  family: "Nunito",
  fonts: [
    {
      src: require("../../assets/fonts/NunitoSans-Regular.ttf"),
      fontWeight: "normal",
    },
    {
      src: require("../../assets/fonts/NunitoSans-Bold.ttf"),
      fontWeight: "bold",
    },
    {
      src: require("../../assets/fonts/NunitoSans-Light.ttf"),
      fontWeight: "light",
    },
    {
      src: require("../../assets/fonts/NunitoSans-SemiBold.ttf"),
      fontWeight: "600",
    },
    // Add more font weights as needed
  ],
});

const styles = StyleSheet.create({
  image: {
    width: 20,
    height: 20,
    marginBottom: 10,
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    backgroundColor: "white",
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Nunito",
    fontWeight: "bold",
    color: "#012970",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
    fontFamily: "Nunito",
    fontWeight: "normal",
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Nunito",
    fontWeight: "bold",
  },
  card: {
    backgroundColor: "white",
    borderRadius: 10,
    padding: 20,
  },
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomWidth: 0.4,
  },
  question: {
    flex: 1,
    fontSize: 14,
    // borderRightWidth: 0.4,
    padding: 10,
    textAlign: "justify",
    fontFamily: "Nunito",
    // fontWeight:'bold',
  },
  answer: {
    flex: 2,
    fontSize: 14,
    padding: 10,
    textAlign: "justify",
    fontFamily: "Nunito",
    fontWeight: "normal",
    textAlign: "right",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
    fontFamily: "Nunito",
    fontWeight: "light",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  pageDate: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "right",
    color: "grey",
    marginRight: 35,
  },
  checkboxContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  checkbox: {
    width: 32,
    height: 32,
    borderWidth: 1,
    borderColor: "black",
    color: "black",
    marginRight: 5,
    textAlign: "center",
    fontSize: 10,
    // fontFamily: 'Nunito',
  },
  label: {
    fontSize: 12,
  },
});

const PDF = ({ data }) => {
  const image = data?.companyID?.imageURL
    ? `${keys.BASE_LOCAL_URL}/${data?.companyID?.imageURL}`
    : imagePlaceholder;
  const capitaliseFirstLetter = (str) => {
    let updatedStr = str.substring(0, 1).toUpperCase();
    return `${updatedStr}${str.substring(1, str.length)}`;
  };
  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.header} fixed>
          CompCheck
        </Text>
        <Text style={styles.header} fixed>
          {data.companyID.name}
        </Text>
        <View
          style={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            src={image}
            style={{ width: 50, height: 50, marginBottom: 10 }}
          />
          <Text style={styles.title}>
            {capitaliseFirstLetter(data?.forFeature)}
          </Text>
        </View>
        <View style={styles.card}>
          <View>
            {data?.shareableID?.shareable?.map((item, index) => (
              <View key={index} style={styles.container}>
                <Text style={styles.question}>{item.title || item.name}</Text>
                {item.lastReviewed && (
                  <Text style={styles.answer}>
                    {item.lastReviewed?.substring(0, 10)}
                  </Text>
                )}
                {item.url && (
                  <Link
                    src={`${keys.BASE_LOCAL_URL}/${item.url}`}
                    style={styles.answer}
                  >
                    View
                  </Link>
                )}
              </View>
            ))}
          </View>
        </View>
        <View>
          <View style={styles.checkboxContainer}>
            <Image
              src={checkmark}
              style={{ width: 15, height: 15, marginRight: 10 }}
            />
            <Text style={styles.label}>
              {`I confirm that I have received, read and understood the attached documents. I also understand \nthat if I require any clarifications, I may contact my designated Manager.`}
            </Text>
          </View>
        </View>
        <View>
          <Image
            src={`${keys.BASE_LOCAL_URL}/${data.signature}`}
            style={{ width: 150, height: 70, marginTop: 80 }}
          />
        </View>
        <View
          style={{ width: "150px", borderBottom: "1px solid black", marginTop:'8px' }}
        ></View>
        <Text style={{...styles.label, marginLeft:'50px', marginTop:'15px'}}>{`Signature`}</Text>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
        <Text
          style={styles.pageDate}
          render={() => new Date(data?.createdAt).toLocaleDateString("en-GB")}
          fixed
        />
      </Page>
    </Document>
  );
};

export default PDF;
