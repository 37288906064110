import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";

import { onShow, onDismiss } from "../features/alert/slice";

export default () => {
  const dispatch = useDispatch();
  const { show, type, body } = useSelector(state => state.alert)

  const showAlert = (body, type) => {
    dispatch(onShow({ body, type }))
    if (!show)
      setTimeout(dismissAlert, 3000)
  }
  const dismissAlert = () => dispatch(onDismiss())

  return {
    body,
    type,
    show,
    showAlert,
    dismissAlert
  }
}