import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import {  useNavigate } from "react-router-dom";

import api from "../app/api";
import useAlert from "./useAlert";

import { setNotification, addNotification, seenAll, clearAll } from '../features/notification/slice';
import appContext from "../app/contextState/context";

export default () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { notifications } = useSelector(state => state.notification)
  const appState = useContext(appContext);
  const { setNotificationData,setshowNotification } = appState
  const { showAlert } = useAlert()

  // STATES
  const [loading, setLoading] = useState(false);
  // const [page, setPage] = useState(1);
  // const [limit, setLimit] = useState(10);

  const fetchNotifications = async (page = 1, limit = 10) => {
    setLoading('fetch');
    try {
      const params = [
        (page ? `page=${page}` : ''),
        (limit ? `limit=${limit}` : ''),
      ].filter(item => item);
      const response = await api.get(`/notification?${params?.join('&')}`);
      dispatch(setNotification(response.data))
      setLoading(false);
    } catch (err) {
      const message = err.response ? err.response.data?.message ? err.response.data?.message : err.response.data : err;
      //(message)
      showAlert(message, 'danger');
      setLoading(false);
    }
  }

  const clearAllNotifications = async () => {
    await api.delete(`/notification/clear`);
    dispatch(clearAll());
  }
  const seenAllNotifications = async () => {
    await api.put(`/notification/seen`);
    dispatch(seenAll());
  }

  const newNotification = (data) => {
    dispatch(addNotification(data))
    if(!data.data){
      setshowNotification(true)
      setNotificationData(data)
      setTimeout(() => {
        setshowNotification(false)
      }, 5000);
    }
    
  }

  const onClick = (data) => {
    var route = '';
    var item = { notification: true };
    seenAllNotifications()
    if (data.target) {
      switch (data.target) {
        case 'task': {
          route = `task`;
          item = { ...item, _id: data.id }
          break;
        }
        default:
      }
      navigate(`/${route}`, { state: item })
    }
    else {

      navigate(`/broadcast-details?id=${data._id}&viewOnly=true&title=${data.title}&description=${data.body}`)
    }
  }

  return {
    loading,
    notifications,
    fetchNotifications,
    clearAllNotifications,
    seenAllNotifications,
    newNotification,
    onClick
  }
}